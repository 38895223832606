import { CheckEmailIcon } from 'components/SvgIcons/CheckEmail';
import React from 'react';

interface Props {
  email: string;
}

export const TalentPartners7: React.FC<Props> = ({ email }) => {
  return (
    <div className="flow-content text-center d-flex flex-column align-items-center justify-content-center">
      <CheckEmailIcon />
      <h1 className="mt-20">Thanks for applying! We’ll be in touch soon.</h1>
      <p>
        We’ve sent a confirmation email to
        <br /> {email}
      </p>
    </div>
  );
};
