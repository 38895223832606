import { WithoutId } from './utils.types';
import { BookMeetingInfoQuery_bookMeetingInfo } from './gql-generated/BookMeetingInfoQuery';
import moment, { Moment } from 'moment';
import { AddProjectRequestMutation_addProjectRequest, BookMeetingInput, ProjectRequestInput } from './gql-generated';
import { BookMeetingMutation_bookMeeting } from './gql-generated/BookMeetingMutation';
import {
  CmsSearchServicesQueryVariables,
  CmsSearchServicesQuery_cmsSearchServices,
} from './gql-generated/CmsSearchServicesQuery';
import { CmsSearchSkillsQuery_cmsSearchSkills } from './gql-generated/CmsSearchSkillsQuery';

export interface ProjectRequest extends AddProjectRequestMutation_addProjectRequest {}
export interface NewProjectRequest extends WithoutId<ProjectRequest> {}
export interface ProjectRequestParams extends ProjectRequestInput {}

export const getProjectRequestInputDefaults = (): ProjectRequestInput => ({
  phoneNumber: '',
  companyName: '',
  websiteUrl: null,
  type: null,
  services: null,
  skills: null,
  budgetFromUsd: null,
  budgetToUsd: null,
  whenToStart: null,
  additionalDetails: null,
  firstName: '',
  lastName: '',
  email: '',
  optOutOfEmails: false,
  privacyPolicyAccepted: false,
});

export type Step1ProjectRequest = Pick<
  ProjectRequestParams,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'companyName'
  | 'websiteUrl'
  | 'privacyPolicyAccepted'
  | 'optOutOfEmails'
>;

export type Step2ServicesProjectRequest = Pick<ProjectRequestParams, 'services'>;
export type Step2SkillsProjectRequest = Pick<ProjectRequestParams, 'skills'>;
export type Step3ProjectRequest = Pick<ProjectRequestParams, 'type'>;
export type Step4ProjectRequest = Pick<ProjectRequestParams, 'budgetFromUsd' | 'budgetToUsd' | 'type'>;
export type Step5ProjectRequest = Pick<ProjectRequestParams, 'whenToStart' | 'additionalDetails'>;

export type SiloSearchInput = CmsSearchServicesQueryVariables['input'];

export interface SiloSkill extends CmsSearchSkillsQuery_cmsSearchSkills {}

export interface SiloService extends CmsSearchServicesQuery_cmsSearchServices {}

export interface MeetingInfo extends BookMeetingInfoQuery_bookMeetingInfo {}

export const getMeetingInfoDefaults = (): MeetingInfo => ({
  timezone: moment.tz.guess().split('/')[0],
  durations: [],
  linkAvailabilityByDuration: [],
});

export interface MeetingSlot {
  utcWithOffset: Moment;
  utcOriginal: number;
}

export interface BookMeetingParams extends BookMeetingInput {}

export interface BookedMeeting extends BookMeetingMutation_bookMeeting {}

export const getBookedMeetingDefaults = (): BookedMeeting => ({
  start: null,
  end: null,
  duration: 0,
  subject: '',
  contact: {
    email: '',
    firstName: '',
    lastName: '',
  },
  organizer: {
    email: '',
    firstName: '',
    lastName: '',
  },
});
