import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust4 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust4Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust4
        step4={joinMindtrustPage.joinMindtrustForm.cvInformation}
        onNext={(cvInformation) => joinMindtrustPage.saveApplicationForm({ cvInformation, nextStep: 7 })}
        goBack={() => joinMindtrustPage.goBack(3)}
      />
    </>
  );
});
