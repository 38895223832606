import classNames from 'classnames';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { MtLogoBg } from 'svg/icons-colorful';
import styles from './JoinProgress.module.scss';

const stepTitles = {
  joinMindtrust: ['General', 'History', 'Position', 'Experience', 'Last Details', 'Review'],
  talentPartners: ['General', 'Expertise', 'Skills', 'Languages', 'Last Details', 'Review'],
};

interface Props {
  step: number;
  progress: number;
  formType?: string;
}

export const JoinProgress: React.FC<Props> = ({ progress, step, formType = 'joinMindtrust' }) => {
  return (
    <div className={styles.join_progress}>
      <div className={styles.content}>
        <h2>Your progress</h2>
        <div className={styles.progress_chart}>
          <div className={styles.progress_percent}>{progress}%</div>
          <Doughnut
            data={{
              datasets: [
                {
                  data: [progress, 100 - progress, 20],
                  backgroundColor: ['#008feb', '#2C333C', '#1a222a'],
                  borderWidth: 0,
                },
              ],
            }}
            options={{
              legend: { display: false },
              cutoutPercentage: 90,
              rotation: 2.1,
              showTooltips: false,
            }}
            width={214}
            height={214}
          />
        </div>
        <div className={styles.step_info}>
          <h1>{(stepTitles as any)[formType][step - 1]} Information</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et</p> */}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.progress}>
          {Array(6)
            .fill(0)
            .map((_, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.progress_step}>
                    <div className={classNames(styles.progress_circle, { [styles.active]: index + 1 === step })}>
                      {index + 1}
                    </div>
                    <div className={styles.step_title}>{(stepTitles as any)['talentPartners'][index]}</div>
                  </div>
                  {index !== 5 && <div className={styles.progress_delimiter} />}
                </React.Fragment>
              );
            })}
        </div>
        <div className={styles.footer_summary}>
          Need to walk away and come back? Don’t worry, we’ll save your progress.
        </div>
      </div>
      <MtLogoBg className={styles.bg_logo} />
    </div>
  );
};
