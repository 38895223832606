import React from 'react';
import classNames from 'classnames';
import { Weekday } from 'types/gql-generated';
import styles from './SelectWeekDay.module.scss';
import { FormGroup } from 'reactstrap';

interface Props {
  className?: string;
  selected: Weekday[];
  onSelect: (days: Weekday[]) => void;
}

const WEEK_DAYS = [Weekday.MON, Weekday.TUE, Weekday.WED, Weekday.THU, Weekday.FRI, Weekday.SAT, Weekday.SUN];

export const SelectWeekDay: React.FC<Props> = ({ className, selected, onSelect }) => {
  const [picked, setPicked] = React.useState<{ [key: string]: boolean }>({
    ...WEEK_DAYS.reduce<{ [key: string]: boolean }>((acc, next) => {
      acc[next] = false;
      return acc;
    }, {}),
    ...selected.reduce<{ [key: string]: boolean }>((acc, next) => {
      acc[next] = true;
      return acc;
    }, {}),
  });

  const selectWeekdays = (name: string) => () => {
    const updated = { ...picked, [name]: !picked[name] };
    onSelect(
      Object.keys(updated)
        .filter((key) => !!updated[key])
        .map((s) => s as Weekday),
    );
    setPicked(updated);
  };

  return (
    <FormGroup>
      <label>Select the days available to work</label>
      <div className={classNames('d-flex w-100 justify-content-between mt-3', className)}>
        {WEEK_DAYS.map((day, index) => (
          <div
            key={index}
            className={classNames(styles.week_day, { [styles.selected]: picked[day] })}
            onClick={selectWeekdays(day)}
          >
            {day.toLowerCase().slice(0, 2)}
          </div>
        ))}
      </div>
    </FormGroup>
  );
};
