import gql from 'graphql-tag';

export const SYNC_SILO = gql`
  mutation SyncSiloQL {
    cmsSync {
      skills
      services
      clientTestimonials
      teamTestimonials
      featuredTeamMembers
      jobOffers
    }
  }
`;

export const CHECK_HUB_SPOT = gql`
  mutation HubspotCheckPropertiesQL {
    hubspotCheckProperties {
      objects {
        name
        missingProperties
        definedProperties
        ok
      }
      ok
    }
  }
`;

export const SYNC_HUB_SPOT = gql`
  mutation HubspotSyncPropertiesQL {
    hubspotSyncProperties {
      objects {
        name
        missingProperties
        definedProperties
        ok
      }
      ok
    }
  }
`;

export const BUILD_SITE_HENDRIX = gql`
  mutation BuildSiteHendrixQL {
    buildSiteHendrix
  }
`;

export const BUILD_SITE_MASTER = gql`
  mutation BuildSiteMasterQL {
    buildSiteMaster
  }
`;

export const BUILD_SITE_DYNAMIC = gql`
  mutation BuildSiteDynamicQL {
    buildSiteDynamic
  }
`;