import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust14 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust14Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust14
        step14={joinMindtrustPage.joinMindtrustForm.workReferences}
        onNext={(workReferences) => joinMindtrustPage.saveApplicationForm({ workReferences, nextStep: 15 })}
        goBack={() => joinMindtrustPage.goBack(12)}
      />
    </>
  );
});
