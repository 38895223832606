import React from 'react';
import Select, { GroupedOptionsType, OptionsType } from 'react-select';
import classNames from 'classnames';

interface FormikSpecific {
  setFieldTouched?: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
}

type SelectProps = Omit<Select['props'], 'options'> & {
  options: GroupedOptionsType<any> | OptionsType<any>;
  valid?: boolean;
  invalid?: boolean;
};

export const FormikSelect: React.FC<SelectProps & FormikSpecific> = (props) => {
  const { className, valid, invalid, name = '', setFieldValue, setFieldTouched } = props;

  const onChange = (v: any) => {
    setFieldValue && setFieldValue(name, v.value, true);
  };

  const onBlur = (v: any) => {
    setFieldTouched && setFieldTouched(name, true, true);
  };

  return (
    <Select
      {...props}
      className={classNames(
        'select-container w-100 form-input',
        {
          'is-invalid': invalid,
          'is-valid': valid,
        },
        className,
      )}
      classNamePrefix="select"
      onChange={props.onChange || onChange}
      onBlur={props.onBlur || onBlur}
    />
  );
};
