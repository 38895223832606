import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { talentPartnersRoutes } from '../../routing';
import { useAppStore } from 'store/app-store.hook';
import { TalentPartners1Page } from './TalentPartners1Page';
import { TalentPartners2Page } from './TalentPartners2Page';
import { TalentPartners3Page } from './TalentPartners3Page';
import { TalentPartners4Page } from './TalentPartners4Page';
import { TalentPartners5Page } from './TalentPartners5Page';
import { TalentPartners6Page } from './TalentPartners6Page';
import { TalentPartners7Page } from './TalentPartners7Page';

import { JoinProgress } from 'components/JoinProgress';
import { NoAuthLayout } from 'layouts/NoAuthLayout';
import { TalentPartnersLayout } from 'layouts/TalentPartnersLayout';
import { Progress } from 'components/Progress';
import { Loader } from 'components/Loader';

export const TalentPartnersRoutes: React.FC = observer(() => {
  const { step } = useParams<{ step: string }>();

  const { talentPartnersPage } = useAppStore().ui;

  const { nextStep } = talentPartnersPage;
  // if (+step > nextStep) {
  //   return <Redirect to={talentPartnersRoutes.request(nextStep)} />;
  // }

  const getProgressStep = (step: number) => {
    // General steps
    if (step === 0) return 1;
    // Expertise steps
    if (step === 1) return 2;
    // Skills steps
    if (step === 2) return 3;
    // Languages steps
    if (step === 3) return 4;
    // Last Details
    if (step === 4) return 5;
    // Final step
    return 6;
  };

  return (
    <NoAuthLayout
      left={
        <div className="w-100">
          {talentPartnersPage.isLoading && <Loader />}
          <Progress value={Math.floor(((+step - 1) / 6) * 100)} />
          <TalentPartnersLayout>
            <Switch>
              <Route
                exact
                path={talentPartnersRoutes.main}
                component={() => {
                  const { search } = useLocation();
                  return <Redirect to={`${talentPartnersRoutes.request(nextStep)}${search}`} />;
                }}
              />
              <Route exact path={talentPartnersRoutes.request(1)} component={TalentPartners1Page} />
              <Route exact path={talentPartnersRoutes.request(2)} component={TalentPartners2Page} />
              <Route exact path={talentPartnersRoutes.request(3)} component={TalentPartners3Page} />
              <Route exact path={talentPartnersRoutes.request(4)} component={TalentPartners4Page} />
              <Route exact path={talentPartnersRoutes.request(5)} component={TalentPartners5Page} />
              <Route exact path={talentPartnersRoutes.request(6)} component={TalentPartners6Page} />
              <Route exact path={talentPartnersRoutes.request(7)} component={TalentPartners7Page} />
            </Switch>
          </TalentPartnersLayout>
        </div>
      }
      right={
        <JoinProgress
          step={getProgressStep(nextStep - 1)}
          progress={Math.round((100 / 6) * (nextStep - 1))}
          formType="talentPartners"
        />
      }
    />
  );
});
