import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners1 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';
import { useLocation } from 'react-router-dom';

export const TalentPartners1Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  const query = new URLSearchParams(useLocation().search);
  const id = query.get('id');

  React.useEffect(() => {
    if (id) talentPartnersPage.restoreFromServer(id);
  }, [id, talentPartnersPage]);

  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners1
        formValues={talentPartnersPage.talentPartnersForm.basicInformation}
        formErrors={talentPartnersPage.errors?.basicInformation}
        onNext={(basicInformation) => talentPartnersPage.saveApplicationForm({ basicInformation, nextStep: 2 })}
      />
    </>
  );
});
