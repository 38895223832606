interface Routes {
  [key: string]: string | (() => string);
}

export const getRoutesValues = (routes: Routes): string[] => {
  return Object.values(routes).map((r) => ('function' === typeof r ? r() : r));
};

export const authRoutes = {
  LOGIN: '/login',
  GOOGLE: '/authenticate/google',
};

export const dashboardRoutes = {
  DASHBOARD: '/dashboard',
};

export const requestProjectRoutes = {
  main: '/request-project',
  request: (step: string | number = ':step') => `/request-project/${step}`,
  requestResources: (step: string | number = ':step') => `/request-project/${step}/resources`,
};

export const joinMindTrustRoutes = {
  main: '/join-mindtrust',
  request: (step: string | number = ':step') => `/join-mindtrust/${step}`,
};

export const requestDemoRoutes = {
  main: '/request-demo',
  request: (step: string | number = ':step') => `/request-demo/${step}`,
};

export const talentPartnersRoutes = {
  main: '/talent-partners',
  request: (step: string | number = ':step') => `/talent-partners/${step}`,
};