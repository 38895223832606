/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicantType {
  BUSINESS_ENTITY = "BUSINESS_ENTITY",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum AuthenticationScheme {
  GOOGLE = "GOOGLE",
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

export enum EducationType {
  COLLEGE = "COLLEGE",
  GRAD_OR_OTHER = "GRAD_OR_OTHER",
  HIGH_SCHOOL = "HIGH_SCHOOL",
}

export enum ParticipationType {
  BUSINESS = "BUSINESS",
  PROJECT_TEAMS = "PROJECT_TEAMS",
  SPECIFIC_JOB = "SPECIFIC_JOB",
}

export enum ProjectRequestType {
  EXISTING_PROJECT = "EXISTING_PROJECT",
  LEARN_ABOUT = "LEARN_ABOUT",
  NEW_PROJECT = "NEW_PROJECT",
  ONGOING_ASSISTANCE = "ONGOING_ASSISTANCE",
}

export enum Role {
  MT_ADMIN = "MT_ADMIN",
  MT_CONTENT_ADMIN = "MT_CONTENT_ADMIN",
  MT_CONTENT_EDITOR = "MT_CONTENT_EDITOR",
  MT_SUPER_ADMIN = "MT_SUPER_ADMIN",
  SYSTEM_REFRESH_TOKEN = "SYSTEM_REFRESH_TOKEN",
}

export enum Weekday {
  FRI = "FRI",
  MON = "MON",
  SAT = "SAT",
  SUN = "SUN",
  THU = "THU",
  TUE = "TUE",
  WED = "WED",
}

export interface ApplicantAdditionalInformationInput {
  learnMindtrust?: string | null;
  whoReferred?: string | null;
  referralCode?: string | null;
  details?: string | null;
}

export interface ApplicantAvailabilityInput {
  isPartTimeHourly?: boolean | null;
  timezone: string;
  workingTimezonePreference?: string;
  startFrom: string;
  commitment: string;
  rateUsd: number;
  isAvailableHourly?: boolean | null;
  wantTrialProject?: boolean | null;
  workDays: Weekday[];
}

export interface ApplicantBasicInformationInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  type: ApplicantType;
  companyLegalName?: string | null;
  privacyPolicyAccepted: boolean;
}

export interface ApplicantContactInformationInput {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  state?: string | null;
  skypeId?: string | null;
}

export interface ApplicantEducationInput {
  periods?: EducationPeriodInput[] | null;
}

export interface ApplicantInput {
  id?: string | null;
  basicInformation: ApplicantBasicInformationInput;
  contactInformation?: ApplicantContactInformationInput | null;
  personalInformation?: ApplicantPersonalInformationInput | null;
  workExperience?: ApplicantWorkExperienceInput | null;
  education?: ApplicantEducationInput | null;
  participation?: ApplicantParticipationInput | null;
  availability?: ApplicantAvailabilityInput | null;
  skillExperience?: ApplicantSkillExperienceInput | null;
  portfolio?: ApplicantPortfolioInput | null;
  languages?: ApplicantLanguagesInput | null;
  workReferences?: ApplicantWorkReferencesInput | null;
  additionalInformation?: ApplicantAdditionalInformationInput | null;
  submit?: ApplicantSubmitInput | null;
  clientSpecific?: any | null;
}

export interface ApplicantLanguagesInput {
  rateEnglish?: string;
  nativeLanguage?: string | null;
  additionalLanguages?: LanguageInput[] | null;
}

export interface ApplicantParticipationInput {
  type: ParticipationType;
  expertise?: string[] | null;
  role?: string | null;
  jobOfferId?: string | null;
}

export interface ApplicantPersonalInformationInput {
  gender: string;
  pronoun: string;
  birthday: string;
  ssn?: string;
}

export interface ApplicantPortfolioInput {
  portfolioUrls?: string[] | null;
  relevantWorkUrls?: string[] | null;
  awards?: string | null;
}

export interface ApplicantSkillExperienceInput {
  skills: ApplicantSkillInput[];
}

export interface ApplicantSkillInput {
  skill: string;
  experience: number;
  years: number;
}

export interface ApplicantSubmitInput {
  legalName: string;
  personallyCertified: boolean;
  date: string;
}

export interface ApplicantWorkExperienceInput {
  linkedInUrl?: string | null;
  cvUrl: string;
  periods?: WorkExperiencePeriodInput[] | null;
}

export interface ApplicantWorkReferencesInput {
  references?: WorkReferenceInput[] | null;
}

export interface AuthenticationInput {
  scheme: AuthenticationScheme;
  refreshToken?: string | null;
  googleCode?: string | null;
  googleRedirectUri?: string | null;
}

export interface BookMeetingInput {
  projectRequestId: string;
  startTime: any;
  duration: number;
}

export interface CmsSearchInput {
  value?: string | null;
  limit?: number | null;
}

export interface EducationPeriodInput {
  type: EducationType;
  name: string;
  degree?: string | null;
  didGraduate: boolean;
  from?: string | null;
  to?: string | null;
  dateAchieved?: string | null;
}

export interface EmailInput {
  email: string;
}

export interface LanguageInput {
  language: string;
  verbalProficiency: number;
  writtenProficiency: number;
}

export interface ProjectRequestCmsMetaInput {
  silo?: string | null;
  skill?: string | null;
}

export interface ProjectRequestInput {
  firstName: string;
  lastName: string;
  email: string;
  id?: string | null;
  phoneNumber: string;
  companyName: string;
  websiteUrl?: string | null;
  type?: ProjectRequestType | null;
  services?: string[] | null;
  skills?: string[] | null;
  budgetFromUsd?: number | null;
  budgetToUsd?: number | null;
  whenToStart?: string | null;
  additionalDetails?: string | null;
  cmsMeta?: ProjectRequestCmsMetaInput | null;
  isComplete?: boolean | null;
  optOutOfEmails?: boolean | null;
  privacyPolicyAccepted?: boolean | null;
}

export interface WorkExperiencePeriodInput {
  employerName: string;
  designation: string;
  responsibilities: string;
  from: string;
  to: string;
}

export interface WorkReferenceInput {
  firstName: string;
  lastName: string;
  email: string;
  relationship: string;
  companyName: string;
  phoneNumber: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
