import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import * as Yup from 'yup';
import classNames from 'classnames';
import { FormikSelect } from 'components/inputs/FormikSelect';
import { COMPANY_SIZE_SELECT_OPTIONS, getSelectOption } from 'types/select-types';
import { STATIC_SITE_URI } from 'config';
import {
  basicInformationFormDefaults,
  BasicInformationForm,
  TalentPartnersFormErrors,
} from 'types/talent-partners.types';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { PhoneInput } from 'components/inputs/PhoneInput';
import { validate } from 'utils/form-utils';
import { Link } from 'react-router-dom';

const validationSchema = () =>
  Yup.object()
    .shape({
      firstName: Yup.string()
        .min(3, 'Should be at least 3 characters.')
        .max(25, 'Should be 25 characters or less.')
        .required('First name is required.'),
      lastName: Yup.string()
        .min(3, 'Should be at least 3 characters.')
        .max(25, 'Should be 25 characters or less.')
        .required('Last name is required.'),
      email: Yup.string().email('Email must be a valid email.').required('Email is required.'),
      phoneNumber: Yup.string()
        .test('phoneNumber', 'Phone number must be a valid.', function (item) {
          return isMobilePhone(`${item}`.replace(/(\(|\)|\s|-)/g, ''), undefined, { strictMode: true });
        })
        .required('Phone number is required.'),
      companySize: Yup.string().notRequired(),
      companyLegalName: Yup.string().notRequired(),
      privacyPolicyAccepted: Yup.boolean()
        .oneOf([true], 'Privacy policy must be checked.')
        .required('Privacy policy is required.'),
    })
    .required();

interface Props {
  formValues?: BasicInformationForm | null;
  formErrors?: TalentPartnersFormErrors['basicInformation'];
  onNext: (basicInformationForm: BasicInformationForm) => void;
}

export const TalentPartners1: React.FC<Props> = ({ formValues, formErrors, onNext }) => {
  const formik = useFormik<BasicInformationForm>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: formValues || basicInformationFormDefaults(),
    initialErrors: formErrors,
    onSubmit: onNext,
    validate: validate<BasicInformationForm>(validationSchema),
  });
  const { values, errors, touched, isValid, setErrors, setTouched } = formik;

  React.useEffect(() => {
    if (formErrors) {
      setErrors(formErrors);
      setTouched(formErrors);
    }
  }, [formErrors, setErrors, setTouched]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Tell us about your company.</h1>
        <p>
          This form is only for agencies, studios, and small teams looking to join MindTrust as talent partners. If
          you're an individual interested in working with us, please fill out{' '}
          <Link to="/join-mindtrust/1" className="hyper-link-text">
            this form
          </Link>{' '}
          instead.
        </p>
        <Row>
          <Col md={12} lg={6}>
            <FormGroup>
              <label htmlFor="firstName">
                First name <span className="error-message">*</span>
              </label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First name"
                valid={touched.firstName && !errors.firstName}
                invalid={touched.firstName && !!errors.firstName}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12} lg={6}>
            <FormGroup className="form-group">
              <label htmlFor="lastName">
                Last name <span className="error-message">*</span>
              </label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last name"
                valid={touched.lastName && !errors.lastName}
                invalid={touched.lastName && !!errors.lastName}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <label htmlFor="email">
            Email <span className="error-message">*</span>
          </label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email address"
            autoComplete="email"
            valid={touched.email && !errors.email}
            invalid={touched.email && !!errors.email}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.email}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>
            Phone number <span className="error-message">*</span>
          </label>
          <PhoneInput
            invalid={touched.phoneNumber && !!errors.phoneNumber}
            valid={touched.phoneNumber && !errors.phoneNumber}
            value={values.phoneNumber}
            onChange={(number) => formik.setFieldValue('phoneNumber', number, true)}
            onBlur={() => formik.setFieldTouched('phoneNumber', true)}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback className="order-3">{errors.phoneNumber}</FormFeedback>
        </FormGroup>
        <Row>
          <Col md={12} lg={8}>
            <FormGroup>
              <label htmlFor="companyLegalName">Company name</label>
              <Input
                type="text"
                name="companyLegalName"
                id="companyLegalName"
                placeholder="Company name"
                valid={touched.companyLegalName && !errors.companyLegalName}
                invalid={touched.companyLegalName && !!errors.companyLegalName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.companyLegalName}
              />
              <FormFeedback>{errors.companyLegalName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12} lg={4}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.companySize },
                { 'input-invalid': touched.companySize && !!errors.companySize },
                { 'input-touched': touched.companySize },
                { 'input-valid': touched.companySize && !errors.companySize },
              )}
            >
              <label>Company Size</label>
              <FormikSelect
                className={classNames('form-input m-0', {
                  'is-invalid': !!values.companySize && touched.companySize && !!errors[`companySize`],
                  'is-valid': !!values.companySize && touched.companySize && !errors[`companySize`],
                })}
                placeholder="Select type"
                name="companySize"
                value={
                  getSelectOption(values.companySize, COMPANY_SIZE_SELECT_OPTIONS) || COMPANY_SIZE_SELECT_OPTIONS[0]
                }
                options={COMPANY_SIZE_SELECT_OPTIONS}
                setFieldTouched={formik.setFieldTouched}
                setFieldValue={formik.setFieldValue}
              />
              <FormFeedback>{errors.companySize}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <CustomInput
          type="checkbox"
          id="private-policy"
          name="private-policy"
          className="my-2"
          label={
            <p className="private-policy-description">
              I acknowledge that the MindTrust application process is confidential and that I will not publicly disclose
              details about this process. By submitting you acknowledge that you have read and agree to our{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hyper-link-text"
                href={`${STATIC_SITE_URI}/website-terms`}
              >
                Terms and Conditions
              </a>
              ,{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hyper-link-text"
                href={`${STATIC_SITE_URI}/privacy-policy`}
              >
                Privacy Policy
              </a>
              , and{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hyper-link-text"
                href={`${STATIC_SITE_URI}/cookie-policy`}
              >
                Cookie Policy
              </a>
              .
            </p>
          }
          onChange={() => {
            formik.setFieldValue('privacyPolicyAccepted', !values.privacyPolicyAccepted, true);
          }}
          checked={!!values.privacyPolicyAccepted}
        />
      </div>
      <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
        Next
        <i className="btn-icon-next" />
      </Button>
    </Form>
  );
};
