import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust15 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust15Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust15
        step15={joinMindtrustPage.joinMindtrustForm.additionalInformation}
        onNext={(additionalInformation) =>
          joinMindtrustPage.saveApplicationForm({ additionalInformation, nextStep: 16 })
        }
        goBack={() => joinMindtrustPage.goBack(14)}
      />
    </>
  );
});
