import axios, { AxiosRequestConfig } from 'axios';
import { API_URI } from 'config';

const API_CONFIG: AxiosRequestConfig = {
  baseURL: API_URI,
  timeout: 200000,
  transformResponse: [(data) => JSON.parse(data)],
  headers: {
    'Content-Type': 'application/json',
  },
};

export const HttpConnector = axios.create(API_CONFIG);
