import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners5 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners5Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners5
        step5={talentPartnersPage.talentPartnersForm.additionalInformation}
        onNext={(additionalInformation) =>
          talentPartnersPage.saveApplicationForm({ additionalInformation, nextStep: 6 })
        }
        goBack={() => talentPartnersPage.goBack(4)}
      />
    </>
  );
});
