import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners3 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners3Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners3
        step3={talentPartnersPage.talentPartnersForm.skills}
        onNext={(skills) => talentPartnersPage.saveApplicationForm({ skills, nextStep: 4 })}
        skillsOptions={talentPartnersPage.skillsOptions}
        loadMoreSkillsOptions={talentPartnersPage.searchSkillsOptions}
        goBack={() => talentPartnersPage.goBack(2)}
      />
    </>
  );
});
