import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust12 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';
import { JoinMindtrustForm } from 'types/join-mindtrust.types';

export const JoinMindTrust12Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust12
        step12={joinMindtrustPage.joinMindtrustForm.languages}
        onNext={(languages) =>
          joinMindtrustPage.saveApplicationForm({
            additionalLanguages: languages?.nativeLanguage,
            nativeLanguage: languages?.nativeLanguage,
            // rateEnglish: languages?.rateEnglish, //TODO: this should be enable when the backend api will be updated with adding `rateEnglish` field in language step.
            nextStep: 14,
          } as JoinMindtrustForm )
        }
        goBack={() => joinMindtrustPage.goBack(9)}
      />
    </>
  );
});
