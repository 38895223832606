import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust7 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust7Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust7
        step7={joinMindtrustPage.joinMindtrustForm.participation}
        onNext={(participation) => joinMindtrustPage.saveApplicationForm({ participation, nextStep: 8 })}
        goBack={() => joinMindtrustPage.goBack(4)}
      />
    </>
  );
});
