import { CustomError } from 'ts-custom-error';

export const ERROR_CODES = {
  APPLICANT_EMAIL_IS_TAKEN: 'APPLICANT_EMAIL_IS_TAKEN',
};

export class ServerError extends CustomError {
  public constructor(
    public id: string,
    public status: number,
    public errorCode: string | null = null,
    message?: string,
  ) {
    super(message);
  }
}
