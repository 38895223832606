import moment from 'moment';
import {
  TalentAdditionalInformationInput,
  TalentAvailabilityInput,
  TalentBasicInformationInput,
  TalentContactInformationInput,
  TalentInput,
  TalentLanguagesInput,
  TalentParticipationInput,
  TalentPersonalInformationInput,
  TalentPortfolioInput,
  TalentSkillExperienceInput,
  TalentSubmitInput,
  TalentType,
  TalentWorkExperienceInput,
  TalentWorkReferencesInput,
  EducationPeriodInput,
  EducationType,
  ParticipationType,
  WorkExperiencePeriodInput,
  WorkReferenceInput,
} from './gql-generated';

export interface FileInfo {
  name: string;
  size: number;
  url: string;
}

export interface TalentPartnersInput extends TalentInput {}

export type BasicInformationForm = TalentBasicInformationInput;
export type ContactInformationForm = TalentContactInformationInput;
export type PersonalInformationForm = TalentPersonalInformationInput;
export type CVInformationForm = Pick<TalentWorkExperienceInput, 'linkedInUrl'> & {
  file: File | null;
  fileInfo?: FileInfo;
} & TalentPortfolioInput;
export type WorkingPeriodsForm = Pick<TalentWorkExperienceInput, 'periods'>;

export interface EducationPeriod extends Omit<EducationPeriodInput, 'from' | 'to' | 'dateAchieved'> {
  from?: number | null;
  to?: number | null;
  dateAchieved?: number | null;
}

export type EducationForm = {
  periods?: EducationPeriod[] | null;
};

export type ParticipationForm = TalentParticipationInput;
export type AvailabilityForm = TalentAvailabilityInput;
export type SkillsForm = TalentSkillExperienceInput;
export type PortfolioForm = TalentPortfolioInput;
export type LanguagesForm = TalentLanguagesInput;
export type WorkReferencesForm = TalentWorkReferencesInput;
export type AdditionalInformationForm = TalentAdditionalInformationInput;
export type SubmitForm = TalentSubmitInput;

export interface TalentPartnersForm {
  id?: string;
  nextStep: number;
  basicInformation?: BasicInformationForm | null;
  contactInformation?: ContactInformationForm | null;
  personalInformation?: PersonalInformationForm | null;
  cvInformation?: CVInformationForm | null;
  workingPeriods?: WorkingPeriodsForm | null;
  education?: EducationForm | null;
  participation?: ParticipationForm | null;
  availability?: AvailabilityForm | null;
  skills?: SkillsForm | null;
  portfolio?: PortfolioForm | null;
  languages?: LanguagesForm | null;
  workReferences?: WorkReferencesForm | null;
  additionalInformation?: AdditionalInformationForm | null;
  submit?: SubmitForm | null;
}

export type TalentPartnersFormFields = Omit<TalentPartnersForm, 'id' | 'nextStep'>;

export type TalentPartnersFormErrors = {
  [key in keyof TalentPartnersFormFields]: Partial<Record<keyof Required<TalentPartnersFormFields[key]>, string>>;
};

export const basicInformationFormDefaults = (): BasicInformationForm => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyLegalName: '',
  companySize: '',
  privacyPolicyAccepted: false,
});

export const contactInformationFormDefaults = (): ContactInformationForm => ({
  city: '',
  country: '',
  line1: '',
  postalCode: '',
});

export const personalInformationFormDefaults = (): PersonalInformationForm => ({
  gender: '',
  pronoun: '',
  birthday: '',
  ssn: '',
});

export const cvInformationFormDefaults = (): CVInformationForm => ({
  linkedInUrl: null,
  file: null,
  portfolioUrls: [],
  relevantWorkUrls: [],
  awards: '',
});

export type WorkExperiencePeriod = WorkExperiencePeriodInput;

export const workExperiencePeriodDefaults = (): WorkExperiencePeriod => ({
  employerName: '',
  designation: '',
  responsibilities: '',
  from: '',
  to: '',
});

export const workingPeriodFormDefaults = (): WorkingPeriodsForm => ({
  periods: [],
});

export const educationPeriodDefaults = (): EducationPeriod => ({
  type: EducationType.HIGH_SCHOOL,
  name: '',
  didGraduate: true,
  degree: null,
  from: null,
  to: null,
  dateAchieved: null,
});

export const educationFormDefaults = (): EducationForm => ({
  periods: null,
});

export const participationFormDefaults = (): ParticipationForm => ({
  type: ParticipationType.PROJECT_TEAMS,
  expertise: null,
  role: null,
  jobOfferId: null,
});

export const availabilityFormDefaults = (): AvailabilityForm => ({
  timezone: '',
  startFrom: '',
  commitment: '',
  rateUsd: 0,
  isAvailableHourly: true,
  wantTrialProject: true,
  workDays: [],
});

export const skillsFormDefaults = (): SkillsForm => ({
  skills: [],
});

export const portfolioFormDefaults = (): PortfolioForm => ({
  portfolioUrls: [],
  relevantWorkUrls: [],
  awards: '',
});

export const languagesFormDefaults = (): LanguagesForm => ({
  rateEnglish: '',
  nativeLanguage: '',
  additionalLanguages: [],
});

export type WorkReference = WorkReferenceInput;

export const workReferenceDefaults = (): WorkReference => ({
  firstName: '',
  lastName: '',
  email: '',
  relationship: '',
  companyName: '',
  phoneNumber: '',
});

export const workReferencesDefaults = (): WorkReferencesForm => ({
  references: [],
});

export const additionalInformationFormDefaults = (): AdditionalInformationForm => ({
  timezone: '',
  workingTimezonePreference: '',
  companyCertificates: '',
  isAvailableTime: true,
  learnMindtrust: null,
  referralCode: null,
  details: null,
});

export const submitFormDefaults = (): SubmitForm => ({
  legalName: '',
  personallyCertified: false,
  date: moment().format('MM-DD-YYYY'),
});
