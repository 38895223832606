import { FieldArray, Formik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { CVInformationForm, cvInformationFormDefaults } from 'types/join-mindtrust.types';
import { URL_REGEXP, validate } from 'utils/form-utils';
import * as Yup from 'yup';
import { renderFieldArray } from './WorkExperienceInput';
import { FileInput } from '../../inputs/FileInput/FileInput';
import styles from './JoinMindTrust4.module.scss';
import isURL from 'validator/lib/isURL';

const FILE_SIZE = 10240000;
const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const validationSchema = () =>
  Yup.object()
    .shape({
      linkedInUrl: Yup.string()
        .test('linkedInUrl', 'Link is not correct', (value) =>
          !!value ? isURL(value, { host_whitelist: ['www'] }) : true,
        )
        .nullable()
        .notRequired(),
      file: Yup.mixed<File>().nullable().notRequired().when('fileInfo', {
        is: (fileInfo) => !!fileInfo,
        then: Yup.mixed<File>().nullable().notRequired(),
        otherwise: Yup.mixed<File>()
          .test('fileSize', 'File too large. Max size is 10mb', (value) => value && value.size <= FILE_SIZE)
          .test(
            'fileFormat',
            'Unsupported Format. Please load .pdf or .docx',
            (value) => value && SUPPORTED_FORMATS.includes(value.type),
          ),
      }),
      portfolioUrls: Yup.string().matches(URL_REGEXP, 'Please provide correct url.').nullable().notRequired(),
      relevantWorkUrls: Yup.string().matches(URL_REGEXP, 'Please provide correct url.').nullable().notRequired(),
      // portfolioUrls: Yup.array()
      //   .of(Yup.string().matches(URL_REGEXP, 'Please provide correct url.').required('This is required field'))
      //   .nullable()
      //   .notRequired(),
      // relevantWorkUrls: Yup.array()
      //   .of(Yup.string().matches(URL_REGEXP, 'Please provide correct url.').required('This is required field'))
      //   .nullable()
      //   .notRequired(),
      awards: Yup.string().nullable().notRequired(),
    })
    .required();

interface Props {
  step4?: CVInformationForm | null;
  onNext: (cvInformation: CVInformationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust4: React.FC<Props> = ({ step4, onNext, goBack }) => {
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={step4 || cvInformationFormDefaults()}
      onSubmit={onNext}
      validate={validate<CVInformationForm>(validationSchema)}
    >
      {(formik) => {
        const { values, errors, touched, isValid } = formik;
        return (
          <Form onSubmit={formik.handleSubmit}>
            <div className="flow-content">
              <h1>Work Experience</h1>
              <p>
                Please share your relevant work experience.
              </p>
              <FormGroup>
                <label htmlFor="linkedInUrl">LinkedIn URL</label>
                <Input
                  type="text"
                  name="linkedInUrl"
                  placeholder="www.linkedin.com/in/username"
                  valid={touched.linkedInUrl && !errors.linkedInUrl}
                  invalid={touched.linkedInUrl && !!errors.linkedInUrl}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.linkedInUrl || ''}
                />
                <FormFeedback>{errors.linkedInUrl}</FormFeedback>
              </FormGroup>
              <FileInput
                label="Upload your CV / Resume in .pdf or .docx"
                file={values.file}
                fileInfo={values.fileInfo}
                onChange={(file) => {
                  formik.setFieldTouched('file', true);
                  formik.setFieldValue('file', file, true);
                  if (!file) {
                    formik.setFieldValue('fileInfo', null, true);
                  }
                }}
                error={touched.file ? errors.file : undefined}
              />
              <FormGroup>
                <label htmlFor="portfolioUrls">Portfolio URL</label>
                <Input
                  type="text"
                  name="portfolioUrls"
                  placeholder="Where is your porfolio digitally?"
                  valid={touched.portfolioUrls && !errors.portfolioUrls}
                  invalid={touched.portfolioUrls && !!errors.portfolioUrls}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.portfolioUrls || ''}
                />
                <FormFeedback>{errors.portfolioUrls}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <label htmlFor="relevantWorkUrls">Other Relevant Portfolio URL</label>
                <Input
                  type="text"
                  name="relevantWorkUrls"
                  placeholder="ie. TopTal, Dribbble, Art Station, YouTube, Vimeo, etc."
                  valid={touched.relevantWorkUrls && !errors.relevantWorkUrls}
                  invalid={touched.relevantWorkUrls && !!errors.relevantWorkUrls}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.relevantWorkUrls || ''}
                />
                <FormFeedback>{errors.relevantWorkUrls}</FormFeedback>
              </FormGroup>
              {/* <div className={styles.inputs_wrapper}>
                <FieldArray
                  name="portfolioUrls"
                  render={renderFieldArray({
                    ...formik,
                    label: 'Portfolio URL',
                    name: 'portfolioUrls',
                    placeholder: 'Where is your porfolio digitally?',
                  })}
                />
                <FieldArray
                  name="relevantWorkUrls"
                  render={renderFieldArray({
                    ...formik,
                    label: 'Other Relevant Portfolio URL',
                    name: 'relevantWorkUrls',
                    placeholder: 'ie. TopTal, Dribbble, Art Station, YouTube, Vimeo, etc.',
                  })}
                />
              </div> */}
              <FormGroup>
                <label htmlFor="awards">List any awards or Special honors</label>
                <Input
                  name="awards"
                  type="textarea"
                  placeholder="Care to share?"
                  valid={touched.awards && !errors.awards}
                  invalid={touched.awards && !!errors.awards}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.awards || ''}
                />
                <FormFeedback>{errors.awards}</FormFeedback>
              </FormGroup>
            </div>
            <Row className="gap-xs">
              <Col xs={6}>
                <Button className="btn-block c-gray" color="default" onClick={goBack}>
                  <i className="btn-icon-back" />
                  Back
                </Button>
              </Col>
              <Col xs={6}>
                <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
                  Next
                  <i className="btn-icon-next" />
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
