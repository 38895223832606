import { useFormik } from 'formik';
import React from 'react';
import { Button, CustomInput, Form, FormFeedback, FormGroup, Input, Col, Row } from 'reactstrap';
import { SubmitForm, submitFormDefaults } from 'types/join-mindtrust.types';
import { validate } from 'utils/form-utils';
import { convertIsoToUsa, convertUsaToIso } from 'utils/timezone-utils';
import * as Yup from 'yup';

const validationSchema = () =>
  Yup.object()
    .shape({
      legalName: Yup.string()
        .min(3, 'Should be at least 3 characters.')
        .max(25, 'Should be 25 characters or less.')
        .required('Field is required'),
      personallyCertified: Yup.boolean().required('Field is required'),
      date: Yup.string().required('Field is required'),
    })
    .required();

interface Props {
  step16?: SubmitForm | null;
  onNext: (step16: SubmitForm) => void;
  goBack: () => void;
}

export const JoinMindTrust16: React.FC<Props> = ({ step16, onNext, goBack }) => {
  const formik = useFormik<SubmitForm>({
    enableReinitialize: true,
    initialValues: step16
      ? {
          ...step16,
          date: convertIsoToUsa(step16.date),
        }
      : submitFormDefaults(),
    validateOnMount: true,
    onSubmit: (step16) =>
      onNext({
        ...step16,
        date: convertUsaToIso(step16.date),
      }),
    validate: validate<SubmitForm>(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Review and Submit</h1>
        <CustomInput
          className="mt-3"
          type="checkbox"
          id="personallyCertified"
          name="personallyCertified"
          label={
            <div>
              I certify that all information and answers provided by me above is true and complete to the best of my
              knowledge. I understand that if this application leads to employment, any false or misleading information
              or answer in my application may lead to my contract being terminated.
            </div>
          }
          onChange={() => {
            formik.setFieldValue('personallyCertified', !values.personallyCertified, true);
          }}
          checked={!!values.personallyCertified}
        />
        <FormGroup className="mt-3">
          <label htmlFor="firstName">First name <span className="error-message">*</span></label>
          <Input
            type="text"
            name="legalName"
            placeholder="Your full legal name"
            valid={touched.legalName && !errors.legalName}
            invalid={touched.legalName && !!errors.legalName}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.legalName}
          />
          <FormFeedback>{errors.legalName}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="firstName">Date</label>
          <Input
            type="text"
            name="date"
            valid={touched.date && !errors.date}
            invalid={touched.date && !!errors.date}
            required
            disabled
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.date}
          />
          <FormFeedback>{errors.legalName}</FormFeedback>
        </FormGroup>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
