import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import { FormikSelect } from 'components/inputs/FormikSelect';
import classNames from 'classnames';
import { getSelectOption, LANGUAGES_SELECT_OPTIONS, RATE_ENGLISH_SELECT_OPTIONS } from 'types/select-types';
import { LanguagesForm, languagesFormDefaults } from 'types/talent-partners.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      rateEnglish: Yup.string().nullable().required('Rate your english is required'),
      nativeLanguage: Yup.string().nullable().notRequired(),
    })
    .required();

interface Props {
  step4?: LanguagesForm | null;
  onNext: (talentPartners: LanguagesForm) => void;
  goBack: () => void;
}

export const TalentPartners4: React.FC<Props> = ({ step4, goBack, onNext }) => {
  const formik = useFormik<LanguagesForm>({
    enableReinitialize: true,
    initialValues: step4 || languagesFormDefaults(),
    validateOnMount: true,
    onSubmit: onNext,
    validate: validate<LanguagesForm>(validationSchema),
  });
  const { values, touched, errors, isValid } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Languages</h1>
        <p>
          Effective communication ensures project success with our US-based clients. We kindly require our talent
          partner's team members to possess at least B1 level of English proficiency in both written and verbal forms
          for seamless collaboration.
        </p>
        <FormGroup>
          <label>
            How do you rate your English proficiency? <span className="error-message">*</span>
          </label>
          <FormikSelect
            className={classNames('form-input', {
              'is-invalid': touched.rateEnglish && !!errors[`rateEnglish`],
              'is-valid': touched.rateEnglish && !errors[`rateEnglish`],
            })}
            placeholder="Select your rate English"
            name="rateEnglish"
            value={getSelectOption(values.rateEnglish, RATE_ENGLISH_SELECT_OPTIONS) || RATE_ENGLISH_SELECT_OPTIONS[0]}
            options={RATE_ENGLISH_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.rateEnglish}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>What is your team's native language (if other than English)?</label>
          <FormikSelect
            className={classNames('form-input', {
              'is-invalid': touched.nativeLanguage && !!errors[`nativeLanguage`],
              'is-valid': touched.nativeLanguage && !errors[`nativeLanguage`],
            })}
            placeholder="Select your nativeLanguage"
            name="nativeLanguage"
            value={getSelectOption(values.nativeLanguage, LANGUAGES_SELECT_OPTIONS) || LANGUAGES_SELECT_OPTIONS[0]}
            options={LANGUAGES_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.nativeLanguage}</FormFeedback>
        </FormGroup>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
