import { SelectedItem } from 'components/SelectedItem';
import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import styles from './JoinMindTrust14.module.scss';
import { WorkReference, workReferenceDefaults, WorkReferencesForm } from 'types/join-mindtrust.types';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { PhoneInput } from '../../inputs/PhoneInput';

const validationSchema = () =>
  Yup.object()
    .shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Email is not correct').required("Reference's email is required"),
      relationship: Yup.string().required("Reference's relationship is required"),
      companyName: Yup.string().required("Reference's company name is required"),
      phoneNumber: Yup.string()
        .test('phoneNumber', 'Number is not correct', function (item) {
          return isMobilePhone(`${item}`.replace(/(\(|\)|\s|-)/g, ''), undefined, { strictMode: true });
        })
        .required('Phone number is required'),
    })
    .required();

interface Props {
  step14?: WorkReferencesForm | null;
  onNext: (joinMindtrust: WorkReferencesForm) => void;
  goBack: () => void;
}

export const JoinMindTrust14: React.FC<Props> = ({ step14, onNext, goBack }) => {
  const workingReferences = step14?.references;
  const [references, setReferences] = React.useState<WorkReference[]>(workingReferences || []);
  const [selectedReference, setSelectedPeriod] = React.useState<WorkReference>(
    (workingReferences && workingReferences[0]) || workReferenceDefaults(),
  );

  const formik = useFormik<WorkReference>({
    initialValues: selectedReference,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (period: WorkReference) => {
      formik.resetForm();
      setSelectedPeriod(workReferenceDefaults());
      setReferences([...references, period]);
    },
    validate: validate<WorkReference>(validationSchema),
  });
  const { values, errors, touched } = formik;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>References</h1>
        <p>Please list three professional references.</p>
        <div className="mb-3 d-flex">
          {references.map((reference, i) => (
            <SelectedItem
              className={styles.selected_item}
              key={`${i}_${reference.companyName}`}
              value={reference.companyName}
              onClick={() => {
                setSelectedPeriod(reference);
                setReferences(references.filter(({ companyName }) => companyName !== reference.companyName));
              }}
              onClose={() =>
                setReferences(references.filter(({ companyName }) => companyName !== reference.companyName))
              }
            />
          ))}
          <Button p={0} size="sm" type="submit" color="primary" className={styles.add_more} outline>
            <i className="icon-add" /> {references.length ? 'More' : 'Add'}
          </Button>
        </div>
        <div className={styles.form_container}>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="firstName">
                  First name <span className="error-message">*</span>
                </label>
                <Input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  valid={touched.firstName && !errors.firstName}
                  invalid={touched.firstName && !!errors.firstName}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.firstName}
                />
                <FormFeedback>{errors.firstName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="lastName">
                  Last name <span className="error-message">*</span>
                </label>
                <Input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  valid={touched.lastName && !errors.lastName}
                  invalid={touched.lastName && !!errors.lastName}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.lastName}
                />
                <FormFeedback>{errors.lastName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <label htmlFor="relationship">
              Relationship <span className="error-message">*</span>
            </label>
            <Input
              type="text"
              name="relationship"
              placeholder="What is your relationship to the reference?"
              autoComplete="relationship"
              valid={touched.relationship && !errors.relationship}
              invalid={touched.relationship && !!errors.relationship}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={values.relationship}
            />
            <i className="glyphicon glyphicon-user form-control-feedback" />
            <FormFeedback>{errors.relationship}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="companyName">
              Company <span className="error-message">*</span>
            </label>
            <Input
              type="text"
              name="companyName"
              placeholder="What company does your reference work for?"
              autoComplete="companyName"
              valid={touched.companyName && !errors.companyName}
              invalid={touched.companyName && !!errors.companyName}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={values.companyName}
            />
            <i className="glyphicon glyphicon-user form-control-feedback" />
            <FormFeedback>{errors.companyName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="email">
              Email <span className="error-message">*</span>
            </label>
            <Input
              type="email"
              name="email"
              placeholder="Email address"
              autoComplete="email"
              valid={touched.email && !errors.email}
              invalid={touched.email && !!errors.email}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={values.email}
            />
            <i className="glyphicon glyphicon-user form-control-feedback" />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label>Phone number</label>
            <PhoneInput
              invalid={touched.phoneNumber && !!errors.phoneNumber}
              valid={touched.phoneNumber && !errors.phoneNumber}
              value={values.phoneNumber}
              onChange={(number) => formik.setFieldValue('phoneNumber', number, true)}
              onBlur={() => formik.setFieldTouched('phoneNumber', true)}
            />
            <i className="glyphicon glyphicon-user form-control-feedback" />
            <FormFeedback className="order-3">{errors.phoneNumber}</FormFeedback>
          </FormGroup>
        </div>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className="btn-block"
            color="primary"
            onClick={() => {
              onNext({ references });
            }}
            disabled={references?.length < 3}
          >
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
