import React from 'react';
import { Col, Row } from 'reactstrap';
import { ExpertiseBadge } from './ExpertiseBadge';

interface Props {
  className?: string;
  selected: string[];
  onSelect: (selected: string[]) => void;
}

export const SelectExpertise: React.FC<Props> = ({ className, selected, onSelect }) => {
  const [picked, setPicked] = React.useState<{ [key: string]: boolean }>({
    ...{ strategy: false, development: false, production: false, design: false, marketing: false, technology: false },
    ...selected.reduce<{ [key: string]: boolean }>((acc, next) => {
      acc[next] = true;
      return acc;
    }, {}),
  });

  const selectExpertise = (name: string) => () => {
    const updated = { ...picked, [name]: !picked[name] };
    onSelect(Object.keys(updated).filter((key) => !!updated[key]));
    setPicked(updated);
  };

  return (
    <div className={className}>
      <h3>What is your expertise?</h3>
      <p className="m-0">You may select more than one.</p>
      <Row>
        <Col>
          <ExpertiseBadge
            className="mt-2"
            name="strategy"
            selected={picked.strategy}
            onClick={selectExpertise('strategy')}
          />
          <ExpertiseBadge
            className="mt-2"
            name="development"
            selected={picked.development}
            onClick={selectExpertise('development')}
          />
          <ExpertiseBadge
            className="mt-2"
            name="production"
            selected={picked.production}
            onClick={selectExpertise('production')}
          />
        </Col>
        <Col>
          <ExpertiseBadge className="mt-2" name="design" selected={picked.design} onClick={selectExpertise('design')} />
          <ExpertiseBadge
            className="mt-2"
            name="marketing"
            selected={picked.marketing}
            onClick={selectExpertise('marketing')}
          />
          <ExpertiseBadge
            className="mt-2"
            name="technology"
            selected={picked.technology}
            onClick={selectExpertise('technology')}
          />
        </Col>
      </Row>
    </div>
  );
};
