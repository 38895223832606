import { ServicesStore } from 'store/services/services.store';
import { JoinMindtrustDomain } from './join-mindtrust.domain';
import { TalentPartnersDomain } from './talent-partners.domain';
import { RequestDemoDomain } from './request-demo.domain';
import { RequestProjectDomain } from './request-project.domain';
import { ReviewsDomain } from './reviews.domain';

export class DomainsStore {
  public requestDemo: RequestDemoDomain;
  public requestProject: RequestProjectDomain;
  public joinMindtrust: JoinMindtrustDomain;
  public talentPartners: TalentPartnersDomain;
  public reviews: ReviewsDomain;

  constructor(servicesStore: ServicesStore) {
    this.requestDemo = new RequestDemoDomain(this, servicesStore);
    this.requestProject = new RequestProjectDomain(this, servicesStore);
    this.joinMindtrust = new JoinMindtrustDomain(this, servicesStore);
    this.talentPartners = new TalentPartnersDomain(this, servicesStore);
    this.reviews = new ReviewsDomain(this, servicesStore);
  }
}
