import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { ParticipationType } from 'types/gql-generated';
import { SelectExpertise } from './SelectExpertise';
import { ParticipationForm, participationFormDefaults } from 'types/talent-partners.types';

interface Props {
  step2?: ParticipationForm | null;
  onNext: (talentPartners: ParticipationForm) => void;
  goBack: () => void;
}

export const TalentPartners2: React.FC<Props> = ({ step2, onNext, goBack }) => {
  const participation = step2 || participationFormDefaults();
  const [type, setType] = React.useState<ParticipationType>(participation.type);
  const [expertise, setExpertise] = React.useState<string[]>(participation.expertise || []);
  const [role, setRole] = React.useState<string | null>(participation.role || null);
  return (
    <div>
      <div className="flow-content">
        <h1>What is your team's expertise?</h1>

        <div className="mt-3">
          {type === ParticipationType.PROJECT_TEAMS && (
            <SelectExpertise
              title={''}
              selected={expertise}
              onSelect={(expertise) => {
                setExpertise(expertise);
                setRole(null);
              }}
            />
          )}
        </div>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className="btn-block"
            color="primary"
            disabled={!role && !expertise.length}
            onClick={() => onNext({ type, expertise, role })}
          >
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
