import gql from 'graphql-tag';

export const ADD_PROJECT_REQUEST_MUTATION = gql`
  mutation AddProjectRequestMutation($input: ProjectRequestInput!) {
    addProjectRequest(input: $input) {
      phoneNumber
      companyName
      websiteUrl
      type
      services
      skills
      budgetFromUsd
      budgetToUsd
      whenToStart
      additionalDetails
      firstName
      lastName
      email
      id
      createdAt
      optOutOfEmails
      privacyPolicyAccepted
    }
  }
`;

export const SEARCH_SILO_SERVICES_QUERY = gql`
  query CmsSearchServicesQuery($input: CmsSearchInput!) {
    cmsSearchServices(input: $input) {
      service
    }
  }
`;

export const SEARCH_SILO_SKILLS_QUERY = gql`
  query CmsSearchSkillsQuery($input: CmsSearchInput!) {
    cmsSearchSkills(input: $input) {
      skill
    }
  }
`;

export const SYNC_SILO_MUTATION = gql`
  mutation cmsSyncMutation {
    cmsSync {
      skills
      services
    }
  }
`;

export const BOOK_MEETING_INFO_QUERY = gql`
  query BookMeetingInfoQuery {
    bookMeetingInfo {
      timezone
      durations
      linkAvailabilityByDuration {
        meetingDurationMillis
        availabilities {
          startMillisUtc
          endMillisUtc
        }
      }
    }
  }
`;

export const BOOK_MEETING_MUTATION = gql`
  mutation BookMeetingMutation($input: BookMeetingInput!) {
    bookMeeting(input: $input) {
      start
      end
      duration
      subject
      contact {
        firstName
        lastName
        email
      }
      organizer {
        firstName
        lastName
        email
      }
    }
  }
`;

export const JOIN_MINDTRUST = gql`
  mutation AddApplicantMutation($input: ApplicantInput!) {
    addApplicant(input: $input) {
      id
      isComplete
    }
  }
`;

export const SEND_APPLICANT_ID = gql`
  mutation ForgotApplicantIdMutation($input: EmailInput!) {
    forgotApplicantId(input: $input)
  }
`;

export const RESTORE_APPLICATION_FORM = gql`
  query ApplicantQuery($id: String!) {
    applicant(id: $id) {
      id
      basicInformation {
        firstName
        lastName
        email
        phoneNumber
        type
        companyLegalName
        privacyPolicyAccepted
      }
      contactInformation {
        city
        country
        line1
        line2
        postalCode
        state
        skypeId
      }
      personalInformation {
        gender
        pronoun
        birthday
        
        ssn
      }
      workExperience {
        linkedInUrl
        cvUrl
        periods {
          from
          to
          employerName
          designation
          responsibilities
        }
      }
      education {
        periods {
          type
          name
          degree
          didGraduate
          from
          to
          dateAchieved
        }
      }
      participation {
        type
        expertise
        role
        jobOfferId
      }
      availability {
        timezone
        workingTimezonePreference
        startFrom
        commitment
        rateUsd
        isAvailableHourly
        wantTrialProject
        workDays
      }
      skillExperience {
        skills {
          skill
          experience
          years
        }
      }
      portfolio {
        portfolioUrls
        relevantWorkUrls
        awards
      }
      languages {
        nativeLanguage
        additionalLanguages {
          language
          verbalProficiency
          writtenProficiency
        }
      }
      workReferences {
        references {
          firstName
          lastName
          email
          relationship
          companyName
          phoneNumber
        }
      }
      additionalInformation {
        learnMindtrust
        referralCode
        details
      }
      submit {
        legalName
        personallyCertified
        date
      }
      createdAt
      isComplete
      clientSpecific
    }
  }
`;
