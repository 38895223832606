import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import { MultipleSelect } from 'components/inputs/MultipleSelect';
import { SkillsForm, skillsFormDefaults } from '../../../types/talent-partners.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      skills: Yup.array()
        .min(1)
        .of(
          Yup.object()
            .shape({
              skill: Yup.string().required(),
              experience: Yup.number().required(),
              years: Yup.number().required(),
            })
            .required(),
        )
        .required(),
    })
    .required();

interface Props {
  step3?: SkillsForm | null;
  skillsOptions: string[];
  onNext: (step3: SkillsForm) => void;
  goBack: () => void;
  loadMoreSkillsOptions: (input?: string) => void;
}

export const TalentPartners3: React.FC<Props> = ({ step3, skillsOptions, loadMoreSkillsOptions, goBack, onNext }) => {
  const formik = useFormik<SkillsForm>({
    enableReinitialize: true,
    initialValues: step3 || skillsFormDefaults(),
    validateOnMount: true,
    onSubmit: onNext,
    validate: validate<SkillsForm>(validationSchema),
  });
  const { values, isValid } = formik;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>What are your team's top skills?</h1>
        <p>
          Please only list skills where your team has expert experience and could immediately contribute to advanced project teams.
        </p>
        <div style={{ height: '24rem' }}>
          <MultipleSelect
            placeholder="Search skills directory"
            title="Search results"
            selected={values.skills.map(({ skill }) => skill) || []}
            options={skillsOptions}
            onSelect={(values: string[]) => {
              formik.setFieldValue(
                'skills',
                values.map((skill) => ({ skill, experience: 0, years: 0 })),
                true,
              );
            }}
            loadOptions={loadMoreSkillsOptions}
          />
        </div>
      </div>
      <Row className="gap-xs mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
