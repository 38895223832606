import { HttpConnector } from 'store/http-connector';
import { GQLConnector } from 'store/qgl-connector';
import { RequestService } from './request-service';
import { TestService } from './test-service';

export class ServicesStore {
  public qglConnector: GQLConnector;
  public requestService: RequestService;
  public testService: TestService;
  public httpConnector = HttpConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
    this.testService = new TestService(qglConnector);
    this.requestService = new RequestService(this);
  }
}
