import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';
import { FormikSelect } from 'components/inputs/FormikSelect';
import {
  COMMITMENT_SELECT_OPTIONS,
  getSelectOption,
  TIME_ZONE_SELECT_OPTIONS,
  YEAR_SELECT_OPTIONS,
  MONTH_SELECT_OPTIONS,
} from 'types/select-types';
import { SelectWeekDay } from './SelectWeekDay';
import { Weekday } from 'types/gql-generated';
import { AvailabilityForm, availabilityFormDefaults } from 'types/join-mindtrust.types';
import styles from './JoinMindTrust8.module.scss';
import moment from 'moment';
import { convertIsoToUsa, convertUsaToIso } from 'utils/timezone-utils';

const validationSchema = () =>
  Yup.object()
    .shape({
      timezone: Yup.string().nullable().required('Time zone is required'),
      startFrom: Yup.string()
        .test('from', 'Can’t be earlier then today.', function (item) {
          return moment(item, 'YYYY-MM-DD').isSameOrAfter(moment());
        })
        .test('startFrom', 'Please enter in MM-DD-YYYY format', function (item) {
          return moment(item, 'YYYY-MM-DD').isValid();
        })
        .required('Start date is required'),
      commitment: Yup.string().required('Desired commitment is required'),
      rateUsd: Yup.number()
        .nullable()
        .min(1, 'Pay rate must be greater than or equal to 1')
        .required('Pay rate must be greater than or equal to 1')
        .typeError('Pay rate must be greater than or equal to 1'),
      isAvailableHourly: Yup.boolean().nullable().notRequired(),
      isPartTimeHourly: Yup.boolean().nullable().notRequired(),
      wantTrialProject: Yup.boolean().nullable().notRequired(),
      workDays: Yup.array<Weekday>()
        .min(1)
        .of<Weekday>(Yup.mixed<Weekday>().oneOf(Object.values(Weekday)))
        .required('Available start day is required'),
    })
    .required();

interface Props {
  step8?: AvailabilityForm | null;
  onNext: (joinMindTrust: AvailabilityForm) => void;
  goBack: () => void;
}

export const JoinMindTrust8: React.FC<Props> = ({ step8, goBack, onNext }) => {
  const startFromByToday = moment().add(1, 'days').format('MM-DD-YYYY');
  const [focus, setFocused] = React.useState<{ focused: boolean }>({ focused: false });

  const formik = useFormik<AvailabilityForm>({
    enableReinitialize: true,
    initialValues: step8
      ? {
          ...step8,
          startFrom: convertIsoToUsa(step8.startFrom),
        }
      : {
          ...availabilityFormDefaults(),
          startFrom: startFromByToday,
        },
    validateOnMount: true,
    onSubmit: (step8) => onNext({ ...step8, startFrom: convertUsaToIso(step8.startFrom) }),
    validate: validate<AvailabilityForm>(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Work Availability</h1>
        <Row>
          <Col md={12} lg={12}>
            <FormGroup>
              <label>
                Time zone <span className="error-message">*</span>
              </label>
              <FormikSelect
                name="timezone"
                placeholder="Select timezone"
                invalid={touched.timezone && !!errors[`timezone`]}
                valid={touched.timezone && !errors[`timezone`]}
                value={getSelectOption(values.timezone, TIME_ZONE_SELECT_OPTIONS) || TIME_ZONE_SELECT_OPTIONS[0]}
                options={TIME_ZONE_SELECT_OPTIONS}
                setFieldValue={(fieldName, fieldValue, validate) => {
                  formik.setFieldValue(fieldName, fieldValue, validate);
                  if (!values.workingTimezonePreference) {
                    formik.setFieldValue('workingTimezonePreference', fieldValue, validate);
                    formik.setFieldTouched('workingTimezonePreference', true, validate);
                  }
                }}
                setFieldTouched={formik.setFieldTouched}
              />
              <FormFeedback>{errors.timezone}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <FormGroup className="custom-datepicker">
              <label htmlFor="startFrom">
                Date available to start <span className="error-message">*</span>
              </label>
              <div
                className={classNames('form-input', {
                  'is-invalid': touched.startFrom && !!errors[`startFrom`],
                  'is-valid': touched.startFrom && !errors[`startFrom`],
                })}
              >
                <SingleDatePicker
                  id="date-picker"
                  date={values.startFrom ? moment(values.startFrom, 'YYYY-MM-DD') : null}
                  focused={focus.focused}
                  onDateChange={(date) => {
                    formik.setFieldValue('startFrom', date?.format('YYYY-MM-DD'), true);
                  }}
                  onFocusChange={(focused) => {
                    setFocused(focused);
                    formik.setFieldTouched('startFrom', true);
                  }}
                  numberOfMonths={1}
                  placeholder="MM-DD-YYYY"
                  displayFormat="MM-DD-YYYY"
                  isOutsideRange={() => false}
                  hideKeyboardShortcutsPanel
                  renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
                    return (
                      <div className={styles.date_select}>
                        <Select
                          className="select-container select-month select-flex"
                          classNamePrefix="select"
                          value={getSelectOption(month.month(), MONTH_SELECT_OPTIONS) || MONTH_SELECT_OPTIONS[0]}
                          options={MONTH_SELECT_OPTIONS}
                          onChange={(option: any) => onMonthSelect(month, option.label)}
                        />
                        <Select
                          className="select-container select-year"
                          classNamePrefix="select"
                          value={getSelectOption(month.year(), YEAR_SELECT_OPTIONS) || YEAR_SELECT_OPTIONS[0]}
                          options={YEAR_SELECT_OPTIONS}
                          onChange={(option: any) => onYearSelect(month, option.value)}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <FormFeedback>{errors.startFrom}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12} lg={6}>
            <FormGroup>
              <label>
                Desired commitment <span className="error-message">*</span>
              </label>
              <FormikSelect
                className={classNames('form-input', {
                  'is-invalid': touched.commitment && !!errors[`commitment`],
                  'is-valid': touched.commitment && !errors[`commitment`],
                })}
                placeholder="Select your pronoun"
                name="commitment"
                value={getSelectOption(values.commitment, COMMITMENT_SELECT_OPTIONS) || COMMITMENT_SELECT_OPTIONS[0]}
                options={COMMITMENT_SELECT_OPTIONS}
                setFieldTouched={formik.setFieldTouched}
                setFieldValue={formik.setFieldValue}
              />
              <i className="glyphicon glyphicon-user form-control-feedback" />
              <FormFeedback>{errors.commitment}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={8} sm={6}>
            <FormGroup>
              <label htmlFor="rateUsd">What is your desired pay rate?</label>
              <Input
                type="number"
                name="rateUsd"
                placeholder="$0.00"
                valid={touched.rateUsd && !errors.rateUsd}
                invalid={touched.rateUsd && !!errors.rateUsd}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.rateUsd || `$0.00`}
                required
              />
              <FormFeedback>{errors.rateUsd}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs={4} sm={6}>
            <FormGroup className="d-flex h-100 flex-direction-column align-items-center mt-1">
              <label>USD per hour</label>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="d-flex w-100 justify-content-between align-items-center">
          <label>
            Can you work at least 4 hours in the UTC-05:00 (Eastern US) time zone between 8:00 a.m. and 5:00 p.m.?{' '}
          </label>
          <FormGroup className="d-flex justify-content-end align-items-center mb-0 pt-0">
            <CustomInput
              type="radio"
              id="working-time-yes"
              label="Yes"
              onChange={() => {
                formik.setFieldValue('isAvailableHourly', true, true);
              }}
              required
              checked={!!values.isAvailableHourly}
            />
            <CustomInput
              className="ml-3"
              type="radio"
              id="working-time-no"
              label="No"
              onChange={() => {
                formik.setFieldValue('isAvailableHourly', false, true);
              }}
              required
              checked={!values.isAvailableHourly}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup className="d-flex w-100 justify-content-between align-items-center">
          <label>Are you available for part-time hourly work?</label>
          <FormGroup className="d-flex justify-content-end align-items-center mb-0 pt-0">
            <CustomInput
              type="radio"
              id="part-time-yes"
              label="Yes"
              onChange={() => {
                formik.setFieldValue('isPartTimeHourly', true, true);
              }}
              required
              checked={!!values.isPartTimeHourly}
            />
            <CustomInput
              className="ml-3"
              type="radio"
              id="part-time-no"
              label="No"
              onChange={() => {
                formik.setFieldValue('isPartTimeHourly', false, true);
              }}
              required
              checked={!values.isPartTimeHourly}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup className="d-flex w-100 justify-content-between align-items-center">
          <label>Are you willing to do a trial project?</label>
          <FormGroup className="d-flex justify-content-end align-items-center mb-0 pt-0">
            <CustomInput
              type="radio"
              id="trial-yes"
              label="Yes"
              onChange={() => {
                formik.setFieldValue('wantTrialProject', true, true);
              }}
              required
              checked={!!values.wantTrialProject}
            />
            <CustomInput
              className="ml-3"
              type="radio"
              id="trial-no"
              label="No"
              onChange={() => {
                formik.setFieldValue('wantTrialProject', false, true);
              }}
              required
              checked={!values.wantTrialProject}
            />
          </FormGroup>
        </FormGroup>
        <SelectWeekDay selected={values.workDays} onSelect={(wd) => formik.setFieldValue('workDays', wd, true)} />
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
