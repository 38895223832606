import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners6 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners6Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners6
        step6={talentPartnersPage.talentPartnersForm.submit}
        onNext={(submit) => talentPartnersPage.saveApplicationForm({ submit, nextStep: 7 })}
        goBack={() => talentPartnersPage.goBack(5)}
      />
    </>
  );
});
