import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import classNames from 'classnames';
import { FormikSelect } from '../../inputs/FormikSelect/FormikSelect';
import { COUNTRIES_SELECT_OPTIONS, getSelectOption, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import { ContactInformationForm, contactInformationFormDefaults } from 'types/join-mindtrust.types';
import isPostalCode, { PostalCodeLocale } from 'validator/lib/isPostalCode';

const validationSchema = () =>
  Yup.object()
    .shape({
      line1: Yup.string().required('Street address is required.'),
      line2: Yup.string().nullable().notRequired(),
      country: Yup.string().required('Country is required.'),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State / Province is required.'),
      postalCode: Yup.string()
        .test('postalCode', 'Postal code is not correct.', function (item) {
          try {
            return isPostalCode(item, `${this.parent.country}`.toUpperCase() as PostalCodeLocale);
          } catch {}
          return true;
        })
        .required('Postal code is required.'),
    })
    .required();

interface Props {
  step2?: ContactInformationForm | null;
  onNext: (contactInformation: ContactInformationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust2: React.FC<Props> = ({ step2, onNext, goBack }) => {
  const formik = useFormik<ContactInformationForm>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: step2 || contactInformationFormDefaults(),
    onSubmit: onNext,
    validate: validate<ContactInformationForm>(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;

  const isUsa = values.country === 'us';
  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Contact Information</h1>
        <p>Please share your contact information.</p>
        <FormGroup>
          <label htmlFor="line1">Street address <span className="error-message">*</span></label>
          <Input
            type="text"
            name="line1"
            placeholder="What is your street address?"
            valid={touched.line1 && !errors.line1}
            invalid={touched.line1 && !!errors.line1}
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.line1}
          />
          <FormFeedback>{errors.line1}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="address2">Street address 2</label>
          <Input
            type="text"
            name="line2"
            placeholder="Street address 2"
            valid={touched.line2 && !errors.line2}
            invalid={touched.line2 && !!errors.line2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.line2 || ''}
          />
          <FormFeedback>{errors.line2}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>Country <span className="error-message">*</span></label>
          <FormikSelect
            name="country"
            placeholder="Select country"
            invalid={!!values.country && touched.country && !!errors[`country`]}
            valid={!!values.country && touched.country && !errors[`country`]}
            value={getSelectOption(values.country, COUNTRIES_SELECT_OPTIONS) || COUNTRIES_SELECT_OPTIONS[0]}
            options={COUNTRIES_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.country}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">City <span className="error-message">*</span></label>
          <Input
            type="text"
            name="city"
            placeholder="City name"
            valid={touched.city && !errors.city}
            invalid={touched.city && !!errors.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.city}
            required
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.city}</FormFeedback>
        </FormGroup>
        <Row>
          <Col md={12} lg={6}>
            {!isUsa ? (
              <FormGroup>
                <label htmlFor="email">State / Province <span className="error-message">*</span></label>
                <Input
                  type="text"
                  name="state"
                  placeholder="Type your state/ province"
                  valid={touched.state && !errors.state}
                  invalid={touched.state && !!errors.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.state || ''}
                  required
                />
                <i className="glyphicon glyphicon-user form-control-feedback" />
                <FormFeedback>{errors.state}</FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <label>State/ Province <span className="error-message">*</span></label>
                <FormikSelect
                  className={classNames('', {
                    'is-invalid': !!values.state && touched.state && !!errors[`state`],
                    'is-valid': !!values.state && touched.state && !errors[`state`],
                  })}
                  placeholder="Select state"
                  name="state"
                  value={getSelectOption(values.state, USA_STATES_SELECT_OPTIONS) || USA_STATES_SELECT_OPTIONS[0]}
                  options={USA_STATES_SELECT_OPTIONS}
                  setFieldTouched={formik.setFieldTouched}
                  setFieldValue={formik.setFieldValue}
                />
                <i className="glyphicon glyphicon-user form-control-feedback" />
                <FormFeedback>{errors.country}</FormFeedback>
              </FormGroup>
            )}
          </Col>
          <Col md={12} lg={6}>
            <FormGroup>
              <label htmlFor="email">Postal code <span className="error-message">*</span></label>
              <Input
                type="text"
                name="postalCode"
                placeholder="What is your postal code?"
                valid={touched.postalCode && !errors.postalCode}
                invalid={touched.postalCode && !!errors.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values.postalCode}
                required
              />
              <i className="glyphicon glyphicon-user form-control-feedback" />
              <FormFeedback>{errors.postalCode}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
