import React from 'react';
import { useFormik } from 'formik';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import { FormikSelect } from 'components/inputs/FormikSelect';
import { getSelectOption, KNOW_MINDTRUST_SELECT_OPTIONS } from 'types/select-types';
import { AdditionalInformationForm } from 'types/join-mindtrust.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      learnMindtrust: Yup.string().nullable().required('Source is required'),
      whoReferred: Yup.string().when('learnMindtrust', {
        is: 'Referral',
        then: Yup.string().required('Referral name is required'),
      }),
      referralCode: Yup.string().nullable().notRequired(),
      details: Yup.string().nullable().notRequired(),
    })
    .required();

interface Props {
  step15?: AdditionalInformationForm | null;
  onNext: (joinMindTrust: AdditionalInformationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust15: React.FC<Props> = ({ step15, onNext, goBack }) => {
  const formik = useFormik<AdditionalInformationForm>({
    enableReinitialize: true,
    initialValues: {
      ...step15,
    },
    validateOnMount: true,
    onSubmit: onNext,
    validate: validate<AdditionalInformationForm>(validationSchema),
  });
  const { values, errors, touched, isValid } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Additional Information</h1>
        <p>How did you first learn of MindTrust?</p>
        <FormGroup>
          <label>How did you first learn of MindTrust?</label>
          <FormikSelect
            invalid={touched.learnMindtrust && !!errors[`learnMindtrust`]}
            valid={touched.learnMindtrust && !errors[`learnMindtrust`]}
            name="learnMindtrust"
            value={
              getSelectOption(values.learnMindtrust, KNOW_MINDTRUST_SELECT_OPTIONS) || KNOW_MINDTRUST_SELECT_OPTIONS[0]
            }
            options={KNOW_MINDTRUST_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.learnMindtrust}</FormFeedback>
        </FormGroup>
        {(getSelectOption(values.learnMindtrust, KNOW_MINDTRUST_SELECT_OPTIONS)?.value === 'Referral' ||
          KNOW_MINDTRUST_SELECT_OPTIONS[0]?.value === 'Referral') && (
          <FormGroup>
            <label htmlFor="whoReferred">Who referred you?</label>
            <Input
              type="text"
              name="whoReferred"
              placeholder="Who referred you?"
              valid={touched.whoReferred && !errors.whoReferred}
              invalid={touched.whoReferred && !!errors.whoReferred}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={values.whoReferred || undefined}
            />
            <FormFeedback>{errors.whoReferred}</FormFeedback>
          </FormGroup>
        )}
        <FormGroup>
          <label htmlFor="referralCode">Referral code</label>
          <Input
            type="text"
            name="referralCode"
            placeholder="Referral code"
            valid={touched.referralCode && !errors.referralCode}
            invalid={touched.referralCode && !!errors.referralCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.referralCode || undefined}
          />
          <FormFeedback>{errors.referralCode}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="details">Anything else you would like to share?</label>
          <Input
            type="text"
            name="details"
            placeholder="Anything else you would like to share?"
            valid={touched.details && !errors.details}
            invalid={touched.details && !!errors.details}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.details || undefined}
          />
          <FormFeedback>{errors.details}</FormFeedback>
        </FormGroup>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
