import { action, computed, observable, reaction } from 'mobx';
import { DomainsStore } from 'store/domains';
import { RequestProjectDomain } from 'store/domains/request-project.domain';
import {
  BookedMeeting,
  getBookedMeetingDefaults,
  getMeetingInfoDefaults,
  MeetingSlot,
  ProjectRequestParams,
  Step1ProjectRequest,
} from 'types/request.types';
import { UiStore } from './ui.store';
import { requestDemoRoutes } from 'routing';
import { MeetingInfo } from '../../types/request.types';
import { Moment } from 'moment';
import moment from 'moment';
import _ from 'lodash';
import { isWithId } from 'types/utils.types';

const THIRTY_MIN_DURATION = 1800000;

export class DemoRequestPage {
  private requestProjectDomain: RequestProjectDomain;
  @observable private _meetingInfo: MeetingInfo = getMeetingInfoDefaults();
  @observable private _timeZoneOffset: number = 0;
  @observable private _selectedDate: Moment | null = null;
  @observable private _currentStep: number = 1;
  @observable private _bookedMeeting: BookedMeeting = getBookedMeetingDefaults();
  @observable public isLoading: boolean = false;

  constructor(private uiStore: UiStore, private domainsStore: DomainsStore) {
    this.requestProjectDomain = domainsStore.requestProject;
    reaction(
      () => this.currentStep,
      () => {
        const next = this.currentStep;
        const { search } = this.uiStore.routerStore.location;
        this.uiStore.routerStore.push(`${requestDemoRoutes.request(next)}${search}`);
      },
    );
  }

  @computed
  get bookedMeeting(): BookedMeeting {
    return this._bookedMeeting;
  }

  @computed
  get currentStep() {
    return this._currentStep;
  }

  set currentStep(step: number) {
    this._currentStep = Math.max(Math.min(step, 3), 1);
  }

  @computed
  get timeZoneOffset(): number {
    return this._timeZoneOffset;
  }

  @computed
  get selectedDate(): Moment | null {
    return this._selectedDate || this.availableDates[0];
  }

  @computed
  get projectRequest(): ProjectRequestParams {
    return this.domainsStore.requestProject.projectRequest;
  }

  @computed
  get availableDates(): Moment[] {
    const firstAvailable = this._meetingInfo.linkAvailabilityByDuration[0];
    return _.uniqBy(
      (firstAvailable?.availabilities || []).map(({ startMillisUtc }) => moment(startMillisUtc).format('DD-MM-YYYY')),
      (v) => v,
    ).map((date) => moment(date, 'DD-MM-YYYY'));
  }

  @computed
  get meetingSlots(): MeetingSlot[] {
    const metingSlots = this._meetingInfo?.linkAvailabilityByDuration.find(
      ({ meetingDurationMillis }) => meetingDurationMillis === THIRTY_MIN_DURATION,
    );
    const filtered = (metingSlots?.availabilities || []).filter(
      ({ startMillisUtc, endMillisUtc }) =>
        moment(startMillisUtc).isSame(moment(this.selectedDate).utcOffset(this.timeZoneOffset), 'day') &&
        moment(endMillisUtc).isSame(moment(this.selectedDate).utcOffset(this.timeZoneOffset), 'day'),
    );

    return filtered.map(({ startMillisUtc }) => ({
      utcOriginal: startMillisUtc,
      utcWithOffset: moment(startMillisUtc).utcOffset(this.timeZoneOffset),
    }));
  }

  @action.bound
  onDateSelect(date: Moment | null) {
    this._selectedDate = date;
  }

  @action.bound
  onTimezoneChange(offset: number) {
    this._timeZoneOffset = offset;
  }

  @action.bound
  async loadMeetingInfo() {
    try {
      this.isLoading = true;
      this._meetingInfo = await this.domainsStore.requestDemo.loadMeetingInfo();
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  getReview() {
    return this.domainsStore.reviews.getReview();
  }

  @action.bound
  async process1Step(step1: Step1ProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, isComplete: true, ...step1 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 2;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process2Step(slot: MeetingSlot) {
    try {
      if (isWithId(this.projectRequest)) {
        this.isLoading = true;
        this._bookedMeeting = await this.domainsStore.requestDemo.bookMeeting({
          projectRequestId: this.projectRequest.id,
          duration: THIRTY_MIN_DURATION,
          startTime: slot.utcOriginal,
        });
      }
      this.currentStep = 3;
      this.uiStore.toastStore.showSuccessMessage('Request has been sent.');
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async resend() {
    this.uiStore.toastStore.showSuccessMessage('Request has been resent.');
  }

  @action.bound
  goBack() {
    this.uiStore.routerStore.goBack();
  }
}
