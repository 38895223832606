import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust3 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust3Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust3
        formValues={joinMindtrustPage.joinMindtrustForm}
        onNext={(personalInformation) => joinMindtrustPage.saveApplicationForm({ personalInformation, nextStep: 4 })}
        goBack={() => joinMindtrustPage.goBack(2)}
      />
    </>
  );
});
