import moment from 'moment';
import { ServicesStore } from 'store/services/services.store';
import { JoinMindtrustForm, JoinMindTrustInput } from 'types/join-mindtrust.types';
import { SiloSkill, SiloService, SiloSearchInput } from '../../types/request.types';
import { DomainsStore } from './domains.store';

export class JoinMindtrustDomain {
  private localStorageKey = 'join-mindtrust';
  constructor(private domainStore: DomainsStore, private services: ServicesStore) {}

  sendApplicationForm = async (application: JoinMindTrustInput): Promise<string> => {
    const { id } = await this.services.requestService.addApplicant(application);
    return id;
  };

  sendApplicationToToLocalStorage = async (application: JoinMindtrustForm) => {
    localStorage.setItem(this.localStorageKey, JSON.stringify(application));
  };

  restoreFormServer = async (id: string): Promise<JoinMindtrustForm> => {
    const applicationForm = await this.services.requestService.restoreApplicationForm({ id });
    const { createdAt, isComplete, clientSpecific, ...form } = applicationForm;
    return {
      ...form,
      nextStep: clientSpecific?.nextStep || 1,
      cvInformation: clientSpecific?.cvInformation,
      education: {
        periods:
          form.education?.periods?.map((period) => ({
            ...period,
            // convert years into first date
            dateAchieved: period.dateAchieved ? moment(period.dateAchieved, 'YYYY-DD-MM').year() : null,
            from: period.from ? moment(period.dateAchieved, 'YYYY-DD-MM').year() : null,
            to: period.to ? moment(period.dateAchieved, 'YYYY-DD-MM').year() : null,
          })) || [],
      },
    };
  };

  readApplicationFormToLocalStorage = (): JoinMindtrustForm | null => {
    const form = localStorage.getItem(this.localStorageKey);
    return form && JSON.parse(form);
  };

  clearApplicationFormToLocalStorage = () => {
    localStorage.removeItem(this.localStorageKey);
  };

  searchSiloServices = (input: SiloSearchInput): Promise<SiloService[]> => {
    return this.services.requestService.searchSiloServices(input);
  };

  searchSiloSkills = (input: SiloSearchInput): Promise<SiloSkill[]> => {
    return this.services.requestService.searchSiloSkills(input);
  };

  sendEmail = (email: string) => {
    return this.services.requestService.sendEmailWithApplicationId({ email });
  };

  uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    const { data } = await this.services.httpConnector.post('/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.url;
  };
}
