import classNames from 'classnames';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

interface Props {
  invalid?: boolean;
  valid?: boolean;
  value?: string;
  onChange?: (number: string) => {};
  onBlur?: (number: string) => {};
}

export const PhoneInput: React.FC<Props> = ({ value, valid, invalid, onChange, onBlur }) => {
  return (
    <ReactPhoneInput
      containerClass={classNames('react-tel-input form-control', { 'is-valid': valid, 'is-invalid': invalid })}
      inputExtraProps={{
        name: 'phone',
        required: true,
      }}
      defaultCountry="us"
      placeholder="(000) 000-0000"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disableAreaCodes
    />
  );
};
