import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import classNames from 'classnames';
import { FormikSelect } from 'components/inputs/FormikSelect';
import { SingleDatePicker } from 'react-dates';
import {
  getSelectOption,
  GENDER_SELECT_OPTIONS,
  PRONOUN_SELECT_OPTIONS,
  MONTH_SELECT_OPTIONS,
  YEAR_SELECT_OPTIONS,
} from 'types/select-types';
import Select from 'react-select';
import styles from './JoinMindTrust3.module.scss';
import moment from 'moment';
import {
  JoinMindtrustForm,
  PersonalInformationForm,
  personalInformationFormDefaults,
} from 'types/join-mindtrust.types';

const validationSchema = (country?: string) => () =>
  Yup.object()
    .shape({
      gender: Yup.string().nullable().required('Gender is required'),
      pronoun: Yup.string().nullable().required('Pronoun preference is required'),
      birthday: Yup.string()
        .test('birthday', 'Please enter in MM-DD-YYYY format', function (item) {
          return moment(item, 'YYYY-MM-DD').isValid();
        })
        .test('birthday', 'You should be at least 18 years old', function (item) {
          return moment(item, 'YYYY-MM-DD').isSameOrBefore(moment().year(moment().year() - 15));
        })
        .required('Birthday is required'),
    })
    .required();

interface Props {
  formValues?: JoinMindtrustForm;
  onNext: (personalInformation: PersonalInformationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust3: React.FC<Props> = ({ formValues, onNext, goBack }) => {
  const [focus, setFocused] = React.useState<{ focused: boolean }>({ focused: false });
  const [showOther, setShowOther] = React.useState(false);

  const formik = useFormik<PersonalInformationForm>({
    enableReinitialize: true,
    initialValues: formValues?.personalInformation || personalInformationFormDefaults(),
    validateOnMount: true,
    onSubmit: onNext,
    validate: validate<PersonalInformationForm>(validationSchema()),
  });
  const { values, isValid, errors, touched } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Personal Information</h1>
        <p>
          MindTrust prides itself on our cultural diversity and strives to be inclusive of everyone so you can feel
          welcome and protected while disclosing your information.
        </p>
        <FormGroup>
          <label>Gender <span className="error-message">*</span></label>
          <FormikSelect
            invalid={touched.gender && !!errors[`gender`]}
            valid={touched.gender && !errors[`gender`]}
            placeholder="Select gender"
            name="gender"
            value={
              showOther
                ? getSelectOption('other', GENDER_SELECT_OPTIONS)
                : getSelectOption(values.gender, GENDER_SELECT_OPTIONS) || GENDER_SELECT_OPTIONS[0]
            }
            options={GENDER_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={(field, value) => {
              if (value !== 'Other') {
                setShowOther(false);
                formik.setFieldValue(field, value, true);
              } else {
                formik.setFieldValue(field, '', true);
                setShowOther(true);
              }
            }}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          {!showOther && <FormFeedback>{errors.gender}</FormFeedback>}
        </FormGroup>
        {showOther && (
          <FormGroup>
            <label htmlFor="gender">Other Gender <span className="error-message">*</span></label>
            <Input
              type="text"
              name="gender"
              placeholder="What is your gender?"
              valid={touched.gender && !errors.gender}
              invalid={touched.gender && !!errors.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={values.gender || ''}
              required
            />
            <FormFeedback>{errors.gender}</FormFeedback>
          </FormGroup>
        )}
        <FormGroup>
          <label>What pronoun do you prefer? <span className="error-message">*</span></label>
          <FormikSelect
            invalid={touched.pronoun && !!errors[`pronoun`]}
            valid={touched.pronoun && !errors[`pronoun`]}
            placeholder="Select your pronoun"
            name="pronoun"
            value={getSelectOption(values.pronoun, PRONOUN_SELECT_OPTIONS) || PRONOUN_SELECT_OPTIONS[0]}
            options={PRONOUN_SELECT_OPTIONS}
            setFieldTouched={formik.setFieldTouched}
            setFieldValue={formik.setFieldValue}
          />
          <FormFeedback>{errors.pronoun}</FormFeedback>
        </FormGroup>
        <FormGroup className="custom-datepicker ">
          <label>Birthday <span className="error-message">*</span></label>
          <div
            className={classNames('form-input', {
              'is-invalid': touched.birthday && !!errors[`birthday`],
              'is-valid': touched.birthday && !errors[`birthday`],
            })}
          >
            <SingleDatePicker
              id="date-picker"
              date={values.birthday ? moment(values.birthday, 'YYYY-MM-DD') : null}
              focused={focus.focused}
              onDateChange={(date) => {
                formik.setFieldValue('birthday', date?.format('YYYY-MM-DD'), true);
              }}
              onFocusChange={(focused) => {
                setFocused(focused);
                formik.setFieldTouched('birthday', true);
              }}
              numberOfMonths={1}
              placeholder="Select your birthday"
              displayFormat="MM-DD-YYYY"
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              renderMonthElement={({ month, onMonthSelect, onYearSelect }) => {
                return (
                  <div className={styles.date_select}>
                    <Select
                      className="select-container select-month select-flex"
                      classNamePrefix="select"
                      value={getSelectOption(month.month(), MONTH_SELECT_OPTIONS) || MONTH_SELECT_OPTIONS[0]}
                      options={MONTH_SELECT_OPTIONS}
                      onChange={(option: any) => onMonthSelect(month, option.label)}
                    />
                    <Select
                      className="select-container select-year"
                      classNamePrefix="select"
                      value={getSelectOption(month.year(), YEAR_SELECT_OPTIONS) || YEAR_SELECT_OPTIONS[0]}
                      options={YEAR_SELECT_OPTIONS}
                      onChange={(option: any) => onYearSelect(month, option.value)}
                    />
                  </div>
                );
              }}
            />
          </div>
          <FormFeedback>{errors.birthday}</FormFeedback>
        </FormGroup>
      </div>
      <Row>
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button className="btn-block" type="submit" color="primary" disabled={!isValid}>
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
