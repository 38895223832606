import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust2 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust2Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust2
        step2={joinMindtrustPage.joinMindtrustForm.contactInformation}
        onNext={(contactInformation) => joinMindtrustPage.saveApplicationForm({ contactInformation, nextStep: 3 })}
        goBack={() => joinMindtrustPage.goBack(1)}
      />
    </>
  );
});
