import React from 'react';
import classNames from 'classnames';
import { MtLogoDarkIcon } from 'svg/icons-colorful';
import styles from './JoinMindTrustLayout.module.scss';
import { STATIC_SITE_URI } from 'config';

interface Props {
  className?: string;
}

export const JoinMindTrustLayout: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames(styles.joinMindTrustLayout, className)}>
      <MtLogoDarkIcon className={styles.logo} />
      {children}
      <p className="my-3 text-center">
        Need some help?{' '}
        <a href="mailto:hr@mindtrust.com" className="hyper-link-text">
          Email Human Resources.{' '}
        </a>{' '}
        Want to apply for a specific job opportunity? See list of{' '}
        <a target="_blank" rel="noopener noreferrer" className="hyper-link-text" href={`${STATIC_SITE_URI}/jobs/`}>
          Current Job Postings.
        </a>
      </p>
    </div>
  );
};
