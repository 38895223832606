import { GQLConnector } from 'store/qgl-connector';
import * as AuthQueriers from 'queries/auth-queries';
import {
  RegisterQLVariables,
  RegisterPhoneQLVariables,
  MeQL,
  RegisterQL,
  RegisterPhoneQL,
  VerifyEmailQL,
  AuthenticateQLVariables,
  AuthenticateQL,
} from 'types/gql-generated';
import { ResetPasswordQLVariables } from 'types/gql-generated/ResetPasswordQL';
import { RequestResetPasswordQLVariables } from 'types/gql-generated/RequestResetPasswordQL';
import { VerifyInviteQL, VerifyInviteQLVariables } from 'types/gql-generated/VerifyInviteQL';
import { RequestCodeQL } from 'types/gql-generated/RequestCodeQL';
import { VerifyPhoneQLVariables, VerifyPhoneQL } from 'types/gql-generated/VerifyPhoneQL';

export class AuthService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  authenticate = async (input: AuthenticateQLVariables['input']): Promise<AuthenticateQL['authenticate']> =>
    (
      await this.qglConnector.mutate<AuthenticateQL>({
        mutation: AuthQueriers.AUTHENTICATE,
        variables: {
          input,
        },
      })
    ).authenticate;
}
