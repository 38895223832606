import moment from 'moment';
import {
  ApplicantAdditionalInformationInput,
  ApplicantAvailabilityInput,
  ApplicantBasicInformationInput,
  ApplicantContactInformationInput,
  ApplicantInput,
  ApplicantLanguagesInput,
  ApplicantParticipationInput,
  ApplicantPersonalInformationInput,
  ApplicantPortfolioInput,
  ApplicantSkillExperienceInput,
  ApplicantSubmitInput,
  ApplicantType,
  ApplicantWorkExperienceInput,
  ApplicantWorkReferencesInput,
  EducationPeriodInput,
  EducationType,
  ParticipationType,
  WorkExperiencePeriodInput,
  WorkReferenceInput,
} from './gql-generated';

export interface FileInfo {
  name: string;
  size: number;
  url: string;
}

export interface JoinMindTrustInput extends ApplicantInput {}

export type BasicInformationForm = ApplicantBasicInformationInput;
export type ContactInformationForm = ApplicantContactInformationInput;
export type PersonalInformationForm = ApplicantPersonalInformationInput;
export type CVInformationForm = Pick<ApplicantWorkExperienceInput, 'linkedInUrl'> & {
  file: File | null;
  fileInfo?: FileInfo;
} & ApplicantPortfolioInput;
export type WorkingPeriodsForm = Pick<ApplicantWorkExperienceInput, 'periods'>;

export interface EducationPeriod extends Omit<EducationPeriodInput, 'from' | 'to' | 'dateAchieved'> {
  from?: number | null;
  to?: number | null;
  dateAchieved?: number | null;
}

export type EducationForm = {
  periods?: EducationPeriod[] | null;
};

export type ParticipationForm = ApplicantParticipationInput;
export type AvailabilityForm = ApplicantAvailabilityInput;
export type SkillsForm = ApplicantSkillExperienceInput;
export type PortfolioForm = ApplicantPortfolioInput;
export type LanguagesForm = ApplicantLanguagesInput;
export type WorkReferencesForm = ApplicantWorkReferencesInput;
export type AdditionalInformationForm = ApplicantAdditionalInformationInput;
export type SubmitForm = ApplicantSubmitInput;

export interface JoinMindtrustForm {
  id?: string;
  nextStep: number;
  basicInformation?: BasicInformationForm | null;
  contactInformation?: ContactInformationForm | null;
  personalInformation?: PersonalInformationForm | null;
  cvInformation?: CVInformationForm | null;
  workingPeriods?: WorkingPeriodsForm | null;
  education?: EducationForm | null;
  participation?: ParticipationForm | null;
  availability?: AvailabilityForm | null;
  skills?: SkillsForm | null;
  portfolio?: PortfolioForm | null;
  languages?: LanguagesForm | null;
  workReferences?: WorkReferencesForm | null;
  additionalInformation?: AdditionalInformationForm | null;
  submit?: SubmitForm | null;
}

export type JoinMindtrustFormFields = Omit<JoinMindtrustForm, 'id' | 'nextStep'>;

export type JoinMindtrustFormErrors = {
  [key in keyof JoinMindtrustFormFields]: Partial<Record<keyof Required<JoinMindtrustFormFields[key]>, string>>;
};

export const basicInformationFormDefaults = (): BasicInformationForm => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  type: ApplicantType.INDIVIDUAL,
  privacyPolicyAccepted: false,
});

export const contactInformationFormDefaults = (): ContactInformationForm => ({
  city: '',
  country: '',
  line1: '',
  postalCode: '',
});

export const personalInformationFormDefaults = (): PersonalInformationForm => ({
  gender: '',
  pronoun: '',
  birthday: '',
  ssn: '',
});

export const cvInformationFormDefaults = (): CVInformationForm => ({
  linkedInUrl: null,
  file: null,
  portfolioUrls: [],
  relevantWorkUrls: [],
  awards: '',
});

export type WorkExperiencePeriod = WorkExperiencePeriodInput;

export const workExperiencePeriodDefaults = (): WorkExperiencePeriod => ({
  employerName: '',
  designation: '',
  responsibilities: '',
  from: '',
  to: '',
});

export const workingPeriodFormDefaults = (): WorkingPeriodsForm => ({
  periods: [],
});

export const educationPeriodDefaults = (): EducationPeriod => ({
  type: EducationType.HIGH_SCHOOL,
  name: '',
  didGraduate: true,
  degree: null,
  from: null,
  to: null,
  dateAchieved: null,
});

export const educationFormDefaults = (): EducationForm => ({
  periods: null,
});

export const participationFormDefaults = (): ParticipationForm => ({
  type: ParticipationType.PROJECT_TEAMS,
  expertise: null,
  role: null,
  jobOfferId: null,
});

export const availabilityFormDefaults = (): AvailabilityForm => ({
  timezone: '',
  workingTimezonePreference: '',
  startFrom: '',
  commitment: '',
  rateUsd: 0,
  isAvailableHourly: true,
  isPartTimeHourly: true,
  wantTrialProject: true,
  workDays: [],
});

export const skillsFormDefaults = (): SkillsForm => ({
  skills: [],
});

export const portfolioFormDefaults = (): PortfolioForm => ({
  portfolioUrls: [],
  relevantWorkUrls: [],
  awards: '',
});

export const languagesFormDefaults = (): LanguagesForm => ({
  nativeLanguage: '',
  additionalLanguages: [],
});

export type WorkReference = WorkReferenceInput;

export const workReferenceDefaults = (): WorkReference => ({
  firstName: '',
  lastName: '',
  email: '',
  relationship: '',
  companyName: '',
  phoneNumber: '',
});

export const workReferencesDefaults = (): WorkReferencesForm => ({
  references: [],
});

export const additionalInformationFormDefaults = (): AdditionalInformationForm => ({
  learnMindtrust: null,
  referralCode: null,
  details: null,
});

export const submitFormDefaults = (): SubmitForm => ({
  legalName: '',
  personallyCertified: false,
  date: moment().format('MM-DD-YYYY'),
});
