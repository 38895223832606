import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust17 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust17Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  const [email] = React.useState(joinMindtrustPage.joinMindtrustForm.basicInformation?.email || '');

  React.useEffect(() => {
    joinMindtrustPage.clearForm();
  }, [joinMindtrustPage]);

  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust17 email={email} />
    </>
  );
});
