import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { JoinMindTrust9 } from '../../components/JoinMindTrust';
import { useAppStore } from 'store/app-store.hook';

export const JoinMindTrust9Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust9
        step9={joinMindtrustPage.joinMindtrustForm.skills}
        onNext={(skills) => joinMindtrustPage.saveApplicationForm({ skills, nextStep: 12 })}
        skillsOptions={joinMindtrustPage.skillsOptions}
        loadMoreSkillsOptions={joinMindtrustPage.searchSkillsOptions}
        goBack={() => joinMindtrustPage.goBack(8)}
      />
    </>
  );
});
