import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners7 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners7Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  const [email] = React.useState(talentPartnersPage.talentPartnersForm.basicInformation?.email || '');

  React.useEffect(() => {
    talentPartnersPage.clearForm();
  }, [talentPartnersPage]);

  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners7 email={email} />
    </>
  );
});
