import * as requestQueries from './request-queries';
import { ServicesStore } from './services.store';
import {
  AddProjectRequestMutation,
  AddProjectRequestMutationVariables,
} from 'types/gql-generated/AddProjectRequestMutation';
import { BookMeetingInfoQuery } from 'types/gql-generated/BookMeetingInfoQuery';
import { BookMeetingMutationVariables, BookMeetingMutation } from 'types/gql-generated/BookMeetingMutation';
import { AddApplicantMutation, AddApplicantMutationVariables } from 'types/gql-generated/AddApplicantMutation';
import { CmsSearchServicesQueryVariables, CmsSearchServicesQuery } from 'types/gql-generated/CmsSearchServicesQuery';
import { CmsSearchSkillsQueryVariables, CmsSearchSkillsQuery } from 'types/gql-generated/CmsSearchSkillsQuery';
import {
  ForgotApplicantIdMutation,
  ForgotApplicantIdMutationVariables,
} from 'types/gql-generated/ForgotApplicantIdMutation';
import { ApplicantQuery, ApplicantQueryVariables } from 'types/gql-generated/ApplicantQuery';

export class RequestService {
  constructor(private servicesStore: ServicesStore) {}

  addProjectRequest = async (
    input: AddProjectRequestMutationVariables['input'],
  ): Promise<AddProjectRequestMutation['addProjectRequest']> =>
    (
      await this.servicesStore.qglConnector.mutate<AddProjectRequestMutation>({
        mutation: requestQueries.ADD_PROJECT_REQUEST_MUTATION,
        variables: { input },
      })
    ).addProjectRequest;

  searchSiloServices = async (
    input: CmsSearchServicesQueryVariables['input'],
  ): Promise<CmsSearchServicesQuery['cmsSearchServices']> =>
    (
      await this.servicesStore.qglConnector.query<CmsSearchServicesQuery, CmsSearchServicesQueryVariables>({
        query: requestQueries.SEARCH_SILO_SERVICES_QUERY,
        variables: { input },
      })
    ).cmsSearchServices;

  searchSiloSkills = async (
    input: CmsSearchSkillsQueryVariables['input'],
  ): Promise<CmsSearchSkillsQuery['cmsSearchSkills']> =>
    (
      await this.servicesStore.qglConnector.query<CmsSearchSkillsQuery, CmsSearchSkillsQueryVariables>({
        query: requestQueries.SEARCH_SILO_SKILLS_QUERY,
        variables: { input },
      })
    ).cmsSearchSkills;

  bookMeetingInfo = async (): Promise<BookMeetingInfoQuery['bookMeetingInfo']> =>
    (
      await this.servicesStore.qglConnector.query<BookMeetingInfoQuery, {}>({
        query: requestQueries.BOOK_MEETING_INFO_QUERY,
      })
    ).bookMeetingInfo;

  bookMeeting = async (input: BookMeetingMutationVariables['input']): Promise<BookMeetingMutation['bookMeeting']> =>
    (
      await this.servicesStore.qglConnector.mutate<BookMeetingMutation>({
        mutation: requestQueries.BOOK_MEETING_MUTATION,
        variables: { input },
      })
    ).bookMeeting;

  addApplicant = async (input: AddApplicantMutationVariables['input']): Promise<AddApplicantMutation['addApplicant']> =>
    (
      await this.servicesStore.qglConnector.mutate<AddApplicantMutation>({
        mutation: requestQueries.JOIN_MINDTRUST,
        variables: { input },
      })
    ).addApplicant;

  sendEmailWithApplicationId = async (
    input: ForgotApplicantIdMutationVariables['input'],
  ): Promise<ForgotApplicantIdMutation['forgotApplicantId']> =>
    (
      await this.servicesStore.qglConnector.mutate<ForgotApplicantIdMutation>({
        mutation: requestQueries.SEND_APPLICANT_ID,
        variables: { input },
      })
    ).forgotApplicantId;

  restoreApplicationForm = async (id: ApplicantQueryVariables): Promise<ApplicantQuery['applicant']> =>
    (
      await this.servicesStore.qglConnector.query<ApplicantQuery, {}>({
        query: requestQueries.RESTORE_APPLICATION_FORM,
        variables: id,
      })
    ).applicant;
}
