import { AuthService } from './services/auth-service';
import { AuthenticationScheme } from 'types/gql-generated';
import { UserTokens, UserInfo, DecodedToken } from 'types/auth-types';
import { observable, action, computed } from 'mobx';
import { saveTokenToLocalStorage, readTokenToLocalStorage, clearLocalStorage } from 'utils/local-storage.utils';
import { RouterStore } from 'mobx-react-router';
import jwtDecode from 'jwt-decode';
import { ToastStore } from './toast-store';
import { REDIRECT_URI } from 'config';

export class AuthStore {
  private routerStore: RouterStore;
  private toastStore: ToastStore;
  private authService: AuthService;
  @observable private _userTokens: UserTokens | null = null;
  @observable private _me: UserInfo | null = null;
  @observable private _error: string | null = null;

  constructor(authService: AuthService, routerStore: RouterStore, toastStore: ToastStore) {
    this._userTokens = readTokenToLocalStorage();
    this.authService = authService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;
  }

  @computed
  get me() {
    return this._me;
  }

  @computed
  get userTokens() {
    return this._userTokens;
  }

  get error() {
    return this._error || undefined;
  }

  @action
  initialize = async () => {
    try {
      // if (this._userTokens) this._me = (await this.authService.me()).me;
    } catch {
      // clearLocalStorage();
    }
  };

  @action
  loginWithGoogle = async (googleCode: string) => {
    try {
      clearLocalStorage();
      const userTokens = await this.authService.authenticate({
        scheme: AuthenticationScheme.GOOGLE,
        googleCode,
        googleRedirectUri: REDIRECT_URI,
      });
      this._userTokens = saveTokenToLocalStorage(userTokens);
      this.routerStore.push('/');
    } catch (error) {
      clearLocalStorage();
      this._error = error.message;
    }
  };

  @action
  logout = () => {
    this._me = null;
    this._userTokens = null;
    clearLocalStorage();
    this.routerStore.push('/');
  };

  decodeToken = (token: string = ''): DecodedToken => {
    return jwtDecode<DecodedToken>(token);
  };
}
