import { observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import { AuthStore } from './auth-store';
import { GQLConnector } from './qgl-connector';
import { AuthService } from './services/auth-service';
import { PermissionStore } from './permisson-store/index';
import { UiStore } from './ui';
import { ServicesStore } from './services/services.store';
import { DomainsStore } from './domains/domains.store';
import { SyncSiloQL } from 'types/gql-generated/SyncSiloQL';
import { HubspotSyncPropertiesQL } from 'types/gql-generated/HubspotSyncPropertiesQL';
import { HubspotCheckPropertiesQL } from 'types/gql-generated/HubspotCheckPropertiesQL';

export class AppStore {
  @observable private gqlConnector: GQLConnector;
  @observable permissionStore: PermissionStore;
  @observable routerStore: RouterStore;
  @observable authStore: AuthStore;
  private servicesStore: ServicesStore;
  private domainsStore: DomainsStore;
  @observable ui: UiStore;

  @observable siloData: SyncSiloQL['cmsSync'] | null = null;
  @observable syncHubSpotData: HubspotSyncPropertiesQL['hubspotSyncProperties'] | null = null;
  @observable checkHubSpotData: HubspotCheckPropertiesQL['hubspotCheckProperties'] | null = null;
  @observable buildSiteHendrixData: string | null = null;
  @observable buildSiteMasterData: string | null = null;
  @observable buildSiteDynamicData: string | null = null;
  @observable loading = {
    silo: false,
    syncHubspot: false,
    checkHubspot: false,
    buildSitehendrix: false,
    buildSitemaster: false,
    buildSitedynamic: false,
  };

  constructor(routerStore: RouterStore) {
    this.routerStore = routerStore;
    this.gqlConnector = new GQLConnector();
    this.servicesStore = new ServicesStore(this.gqlConnector);
    this.domainsStore = new DomainsStore(this.servicesStore);
    this.ui = new UiStore(routerStore, this.domainsStore);
    this.authStore = new AuthStore(new AuthService(this.gqlConnector), routerStore, this.ui.toastStore);
    this.permissionStore = new PermissionStore(this.authStore);
  }

  syncSilo = async () => {
    try {
      this.loading.silo = true;
      this.siloData = await this.servicesStore.testService.syncSilo();
      this.ui.toastStore.showSuccessMessage('Silo synced!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.silo = false;
  };

  syncHubSpot = async () => {
    try {
      this.loading.syncHubspot = true;
      this.syncHubSpotData = await this.servicesStore.testService.syncHubSpot();
      this.ui.toastStore.showSuccessMessage('HubSpot synced!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.syncHubspot = false;
  };

  checkHubSpot = async () => {
    try {
      this.loading.checkHubspot = true;
      this.checkHubSpotData = await this.servicesStore.testService.checkHubSpot();
      this.ui.toastStore.showSuccessMessage('HubSpot checked!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.checkHubspot = false;
  };

  buildSiteHendrix = async () => {
    try {
      this.loading.buildSitehendrix = true;
      this.buildSiteHendrixData = await this.servicesStore.testService.buildSiteHendrix();
      this.ui.toastStore.showSuccessMessage('BuildSiteHendrix succeed!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.buildSitehendrix = false;
  };

  buildSiteMaster = async () => {
    try {
      this.loading.buildSitemaster = true;
      this.buildSiteMasterData = await this.servicesStore.testService.buildSiteMaster();
      this.ui.toastStore.showSuccessMessage('BuildSiteMaster succeed!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.buildSitemaster = false;
  };

  buildSiteDynamic = async () => {
    try {
      this.loading.buildSitedynamic = true;
      this.buildSiteDynamicData = await this.servicesStore.testService.buildSiteDynamic();
      this.ui.toastStore.showSuccessMessage('BuildSiteDynamic succeed!');
    } catch (error) {
      this.ui.toastStore.showError(error);
    }
    this.loading.buildSitedynamic = false;
  };
}
