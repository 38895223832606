import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners2 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners2Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners2
        step2={talentPartnersPage.talentPartnersForm.participation}
        onNext={(participation) => talentPartnersPage.saveApplicationForm({ participation, nextStep: 3 })}
        goBack={() => talentPartnersPage.goBack(1)}
      />
    </>
  );
});
