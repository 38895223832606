import moment from 'moment-timezone';
import spacetime from 'spacetime';
// @ts-ignore
import { display } from 'spacetime-informal';

export const convertUsaToIso = (date: string): string => moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD');
export const convertIsoToUsa = (date: string): string => moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');

export const timezones = {
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Honolulu': 'Hawaii',
  'America/Juneau': 'Alaska',
  'America/Boise': 'Mountain Time',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Phoenix': 'Arizona',
  'America/Chicago': 'Central Time',
  'America/Regina': 'Saskatchewan',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Belize': 'Central America',
  'America/Detroit': 'Eastern Time',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Caracas': 'Caracas, La Paz',
  'America/Santiago': 'Santiago',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Sao_Paulo': 'Brasilia',
  'America/Tijuana': 'Tijuana, Pacific Time',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Godthab': 'Greenland',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  GMT: 'Dublin, Edinburgh, Lisbon, London',
  'Africa/Casablanca': 'Casablanca,e Monrovia',
  'Atlantic/Canary': 'Canary Islands',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Africa/Algiers': 'West Central Africa',
  'Europe/Bucharest': 'Bucharest',
  'Africa/Cairo': 'Cairo',
  'Europe/Helsinki': 'Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Athens': 'Athens, Istanbul, Minsk',
  'Asia/Jerusalem': 'Jerusalem',
  'Africa/Harare': 'Harare, Pretoria',
  'Europe/Moscow': 'Moscow, St. Petersburg, Volgograd',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Africa/Nairobi': 'Nairobi',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Tehran': 'Tehran',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Kabul': 'Kabul',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Taipei': 'Taipei',
  'Australia/Perth': 'Perth',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Seoul': 'Seoul',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Yakutsk': 'Yakutsk',
  'Australia/Darwin': 'Darwin',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Hobart': 'Hobart',
  'Asia/Vladivostok': 'Vladivostok',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Pacific/Fiji': 'Fiji Islands, Kamchatka, Marshall Islands',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Tongatapu': "Nuku'alofa",
};

interface TimezoneRawOption {
  name: string;
  label: string;
  offset: number;
  altName: string;
}

export interface TimezoneOption {
  label: string;
  value: string;
  offset: number;
}

export const TIME_ZONE_OPTIONS = Object.entries(timezones)
  .reduce<TimezoneRawOption[]>((obj, entry) => {
    const a = spacetime.now().goto(entry[0]);
    const tz = a.timezone();
    const tzDisplay = display(entry[0]);
    let altName = entry[0];
    if (tzDisplay && tzDisplay.daylight && tzDisplay.standard) {
      altName = a.isDST() ? tzDisplay.daylight.name : tzDisplay.standard.name;
    }
    obj.push({
      name: entry[0],
      label: entry[1],
      offset: tz.current.offset,
      altName: altName,
    });
    return obj;
  }, [])
  .sort((a, b) => a.offset - b.offset)
  .reduce<TimezoneOption[]>((acc, tz) => {
    if (tz.offset === undefined) return acc;
    const min = tz.offset * 60;
    const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
    const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${tz.label}`;
    acc.push({
      value: tz.name,
      label: `${prefix} ${!tz.altName.includes('/') ? `(${tz.altName})` : ''}`,
      offset: tz.offset,
    });
    return acc;
  }, []);

export const getUtcDisplay = (offset: number) => {
  const offsetSign = offset > 0 ? '+' : offset < 0 ? '-' : '';
  const offsetPad = ('0' + Math.abs(offset)).slice(-2);
  return `${offsetSign}${offsetPad}:00`;
};

export const getDefaultTimeZone = () => {
  const timeZone = moment.tz.guess();
  return {
    timezone: timeZone,
    offset: Math.round(moment.tz(timeZone).utcOffset() / 60),
    region: timeZone.split('/')[0],
  };
};
