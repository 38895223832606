import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { JoinMindTrust8 } from 'components/JoinMindTrust';

export const JoinMindTrust8Page = observer(() => {
  const { joinMindtrustPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Join MindTrust</title>
      </Helmet>
      <JoinMindTrust8
        step8={joinMindtrustPage.joinMindtrustForm.availability}
        onNext={(availability) => joinMindtrustPage.saveApplicationForm({ availability, nextStep: 9 })}
        goBack={() => joinMindtrustPage.goBack(7)}
      />
    </>
  );
});
