import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { joinMindTrustRoutes } from '../../routing';
import { useAppStore } from 'store/app-store.hook';
import { JoinMindTrust1Page } from './JoinMindTrust1Page';
import { JoinMindTrust2Page } from './JoinMindTrust2Page';
import { JoinMindTrust3Page } from './JoinMindTrust3Page';
import { JoinMindTrust4Page } from './JoinMindTrust4Page';
import { JoinMindTrust5Page } from './JoinMindTrust5Page';
import { JoinMindTrust6Page } from './JoinMindTrust6Page';
import { JoinMindTrust7Page } from './JoinMindTrust7Page';
import { JoinMindTrust8Page } from './JoinMindTrust8Page';
import { JoinMindTrust9Page } from './JoinMindTrust9Page';
import { JoinMindTrust10Page } from './JoinMindTrust10Page';
import { JoinMindTrust11Page } from './JoinMindTrust11Page';
import { JoinMindTrust12Page } from './JoinMindTrust12Page';
import { JoinMindTrust13Page } from './JoinMindTrust13Page';
import { JoinMindTrust14Page } from './JoinMindTrust14Page';
import { JoinMindTrust15Page } from './JoinMindTrust15Page';
import { JoinMindTrust16Page } from './JoinMindTrust16Page';
import { JoinMindTrust17Page } from './JoinMindTrust17Page';
import { JoinProgress } from 'components/JoinProgress';
import { NoAuthLayout } from 'layouts/NoAuthLayout';
import { JoinMindTrustLayout } from 'layouts/JoinMindTrustLayout';
import { Progress } from 'components/Progress';
import { Loader } from 'components/Loader';

export const JoinMindTrustRoutes: React.FC = observer(() => {
  const { step } = useParams<{ step: string }>();

  const { joinMindtrustPage } = useAppStore().ui;

  const { nextStep } = joinMindtrustPage;
  if (+step > nextStep) {
    return <Redirect to={joinMindTrustRoutes.request(nextStep)} />;
  }

  const getProgressStep = (step: number) => {
    // General steps
    if (step < 4) return 1;
    // History steps
    if (step < 7) return 2;
    // Position steps
    if (step < 9) return 3;
    // Experience steps
    if (step < 13) return 4;
    // Last Details
    if (step < 16) return 5;
    // Final step
    return 6;
  };

  return (
    <NoAuthLayout
      left={
        <div className="w-100">
          {joinMindtrustPage.isLoading && <Loader />}
          <Progress value={Math.floor(((+step - 1) / 16) * 100)} />
          <JoinMindTrustLayout>
            <Switch>
              <Route
                exact
                path={joinMindTrustRoutes.main}
                component={() => {
                  const { search } = useLocation();
                  return <Redirect to={`${joinMindTrustRoutes.request(nextStep)}${search}`} />;
                }}
              />
              <Route exact path={joinMindTrustRoutes.request(1)} component={JoinMindTrust1Page} />
              <Route exact path={joinMindTrustRoutes.request(2)} component={JoinMindTrust2Page} />
              <Route exact path={joinMindTrustRoutes.request(3)} component={JoinMindTrust3Page} />
              <Route exact path={joinMindTrustRoutes.request(4)} component={JoinMindTrust4Page} />
              {/* <Route exact path={joinMindTrustRoutes.request(5)} component={JoinMindTrust5Page} /> */}
              {/* <Route exact path={joinMindTrustRoutes.request(6)} component={JoinMindTrust6Page} /> */}
              <Route exact path={joinMindTrustRoutes.request(7)} component={JoinMindTrust7Page} />
              <Route exact path={joinMindTrustRoutes.request(8)} component={JoinMindTrust8Page} />
              {/* TODO:: need to confirm to pull the skills data from Taas with Igor */}
              <Route exact path={joinMindTrustRoutes.request(9)} component={JoinMindTrust9Page} />
              {/* <Route exact path={joinMindTrustRoutes.request(10)} component={JoinMindTrust10Page} /> */}
              {/* <Route exact path={joinMindTrustRoutes.request(11)} component={JoinMindTrust11Page} /> */}
              <Route exact path={joinMindTrustRoutes.request(12)} component={JoinMindTrust12Page} />
              {/* <Route exact path={joinMindTrustRoutes.request(13)} component={JoinMindTrust13Page} /> */}
              <Route exact path={joinMindTrustRoutes.request(14)} component={JoinMindTrust14Page} />
              <Route exact path={joinMindTrustRoutes.request(15)} component={JoinMindTrust15Page} />
              <Route exact path={joinMindTrustRoutes.request(16)} component={JoinMindTrust16Page} />
              <Route exact path={joinMindTrustRoutes.request(17)} component={JoinMindTrust17Page} />
            </Switch>
          </JoinMindTrustLayout>
        </div>
      }
      right={<JoinProgress step={getProgressStep(nextStep - 1)} progress={Math.round((100 / 16) * (nextStep - 1))} />}
    />
  );
});
