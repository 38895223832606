import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './NoAuthLayout.module.scss';

interface Props {
  className?: string;
  left?: ReactElement;
  right?: ReactElement;
}

export const NoAuthLayout: React.FC<Props> = ({ className, left, right }) => {
  return (
    <div className={classNames(styles.noAuthLayout, className)}>
      <div className={classNames(styles.column, styles.left)}>{left}</div>
      <div className={classNames(styles.column, styles.right)}>{right}</div>
    </div>
  );
};
