import classNames from 'classnames';
import * as React from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import { RemoveIcon, UploadIcon } from 'svg/icons-colorful';

interface Props {
  label: string;
  file: File | null;
  fileInfo?: {
    name: string;
    size: number;
    url: string;
  };
  error?: string;
  onChange: (file: File | null) => void;
}

export const FileInput: React.FC<Props> = ({ label, file, fileInfo, error, onChange }) => {
  const [drag, setDrag] = React.useState(false);

  const fileRef = React.createRef<HTMLInputElement>();
  const dropRef = React.createRef<HTMLDivElement>();

  const handleDrag = React.useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  }, []);

  const handleDragIn = React.useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) setDrag(true);
  }, []);

  const handleDragOut = React.useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  }, []);

  const handleDrop = React.useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file) onChange(file);
        e.dataTransfer.clearData();
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    const div = dropRef.current;
    div?.addEventListener('dragenter', handleDragIn);
    div?.addEventListener('dragleave', handleDragOut);
    div?.addEventListener('dragover', handleDrag);
    div?.addEventListener('drop', handleDrop);
    return () => {
      div?.removeEventListener('dragenter', handleDragIn);
      div?.removeEventListener('dragleave', handleDragOut);
      div?.removeEventListener('dragover', handleDrag);
      div?.removeEventListener('drop', handleDrop);
    };
  });

  const handleChange: React.ChangeEventHandler<{ files: FileList | null }> = (event) => {
    const { files } = event.target;
    const file = files && files[0];
    if (file) onChange(file);
  };
  const fileName = file?.name || fileInfo?.name;
  return (
    <FormGroup>
      <label>{label}</label>
      <div ref={dropRef} className={classNames('form-input card p-3', { active: !drag, 'is-invalid': error })}>
        <div className="d-flex text-secondary font-weight-bold">
          <UploadIcon className="mr-2" />
          {!fileName ? (
            <div>
              <span className="text-dark">Drag to upload or</span>{' '}
              <span className="text-primary cursor-pointer" onClick={() => fileRef.current?.click()}>
                select from computer
              </span>
            </div>
          ) : (
            <div className="d-flex align-items-center" onClick={() => onChange(null)}>
              <span className="text-primary mr-2">{fileName}</span> <RemoveIcon />
            </div>
          )}
        </div>
        <input ref={fileRef} type="file" onChange={handleChange} style={{ visibility: 'hidden', height: 0 }} />
      </div>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};
