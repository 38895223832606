import { observable, computed, action } from 'mobx';
import { ReactNode } from 'react';
import { ServerError } from 'types/error.types';

export class ToastStore {
  @observable private _successMessage: string | null = null;
  @observable private _error: ServerError | null = null;

  @computed
  get successMessage() {
    return this._successMessage;
  }

  @computed
  get error() {
    return this._error;
  }

  @action
  showSuccessMessage = (message: string) => {
    this._successMessage = message;
  };

  @action
  showError = (error: ServerError) => {
    this._error = error;
  };

  @action
  clearSuccessMessage = () => {
    this._successMessage = null;
  };
  @action
  clearErrorMessage = () => {
    this._error = null;
  };
}
