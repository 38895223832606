import gql from 'graphql-tag';

export const AUTHENTICATE = gql`
  mutation AuthenticateQL($input: AuthenticationInput!) {
    authenticate(input: $input) {
      token
      refreshToken
      roles
    }
  }
`;
