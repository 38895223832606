import React from 'react';
import classNames from 'classnames';
import styles from './SelectedItem.module.scss';

interface Props {
  className?: string;
  value: string;
  onClick?: () => void;
  onClose: (value: string) => void;
}

export const SelectedItem: React.FC<Props> = ({ className, value, onClick, onClose }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.text} onClick={onClick}>
        {value}
      </div>
      <div className="icon-remove" onClick={() => onClose(value)} />
    </div>
  );
};
