import { DomainsStore } from 'store/domains';
import { RouterStore } from 'mobx-react-router';
import { ProjectRequestPage } from './project-request-page.store';
import { ToastStore } from 'store/toast-store';
import { DemoRequestPage } from './demo-request-page.store';
import { JoinMindtrustPage } from './join-mindtrust-page.store';
import { TalentPartnersPage } from './talent-partners-page.store';

export class UiStore {
  public projectRequestPage: ProjectRequestPage;
  public demoRequestPage: DemoRequestPage;
  public joinMindtrustPage: JoinMindtrustPage;
  public talentPartnersPage: TalentPartnersPage;
  public toastStore: ToastStore;

  constructor(public routerStore: RouterStore, private domainsStore: DomainsStore) {
    this.projectRequestPage = new ProjectRequestPage(this, domainsStore);
    this.demoRequestPage = new DemoRequestPage(this, domainsStore);
    this.joinMindtrustPage = new JoinMindtrustPage(this, domainsStore);
    this.talentPartnersPage = new TalentPartnersPage(this, domainsStore);
    this.toastStore = new ToastStore();
  }
}
