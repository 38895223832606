import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { TalentPartners4 } from '../../components/TalentPartners';
import { useAppStore } from 'store/app-store.hook';

export const TalentPartners4Page = observer(() => {
  const { talentPartnersPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Talent Partner Application</title>
      </Helmet>
      <TalentPartners4
        step4={talentPartnersPage.talentPartnersForm.languages}
        onNext={(languages) =>
          talentPartnersPage.saveApplicationForm({
            languages,
            nextStep: 5,
          })
        }
        goBack={() => talentPartnersPage.goBack(3)}
      />
    </>
  );
});
