import React from 'react';
import { FormikSelect } from 'components/inputs/FormikSelect';
import { Button, Row, Col, FormGroup } from 'reactstrap';
import { ParticipationType } from 'types/gql-generated';
import { BUSINESS_ROLES_SELECT_OPTIONS, getSelectOption } from 'types/select-types';
import { ProjectSide } from './ProjectSide';
import { SelectExpertise } from './SelectExpertise';
import { ParticipationForm, participationFormDefaults } from 'types/join-mindtrust.types';

interface Props {
  step7?: ParticipationForm | null;
  onNext: (joinMindtrust: ParticipationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust7: React.FC<Props> = ({ step7, onNext, goBack }) => {
  const participation = step7 || participationFormDefaults();
  const [type, setType] = React.useState<ParticipationType>(participation.type);
  const [expertise, setExpertise] = React.useState<string[]>(participation.expertise || []);
  const [role, setRole] = React.useState<string | null>(participation.role || null);
  return (
    <div>
      <div className="flow-content">
        <h1>Preferred Role</h1>
        <Row>
          <Col xs={12} sm={6}>
            <FormGroup>
              <ProjectSide
                type="project"
                active={type === ParticipationType.PROJECT_TEAMS}
                onClick={() => setType(ParticipationType.PROJECT_TEAMS)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <ProjectSide
                type="business"
                active={type === ParticipationType.BUSINESS}
                onClick={() => setType(ParticipationType.BUSINESS)}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="mt-3">
          {type === ParticipationType.PROJECT_TEAMS && (
            <SelectExpertise
              selected={expertise}
              onSelect={(expertise) => {
                setExpertise(expertise);
                setRole(null);
              }}
            />
          )}
          {type === ParticipationType.BUSINESS && (
            <FormGroup>
              <label>What is your preferred role?</label>
              <FormikSelect
                placeholder="Select role"
                value={getSelectOption(role, BUSINESS_ROLES_SELECT_OPTIONS) || BUSINESS_ROLES_SELECT_OPTIONS[0]}
                options={BUSINESS_ROLES_SELECT_OPTIONS}
                setFieldValue={(_, value) => {
                  setExpertise([]);
                  setRole(value);
                }}
              />
            </FormGroup>
          )}
        </div>
      </div>
      <Row className="mt-4">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className="btn-block"
            color="primary"
            disabled={!role && !expertise.length}
            onClick={() => onNext({ type, expertise, role })}
          >
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
