import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  getRoutesValues,
  authRoutes,
  dashboardRoutes,
  requestProjectRoutes,
  requestDemoRoutes,
  joinMindTrustRoutes,
  talentPartnersRoutes,
} from './routes';
import { PrivateRoute } from './PrivateRoute';
import { DashboardPage } from 'pages/DashboardPage';
import { ProjectRequestRoutes } from 'pages/ProjectRequestPages';
import { DemoRequestRoutes } from 'pages/DemoRequestPages';
import { JoinMindTrustRoutes } from '../pages/JoinMindTrustPages/JoinMindTrustRoutes';
import { TalentPartnersRoutes } from '../pages/TalentPartnersPages/TalentPartnersRoutes';
import { LoginPage } from 'pages/LoginPage/LoginPage';
import { AuthGoogle } from 'pages/LoginPage/AuthGoogle';

export const AppRouter = () => {
  return (
    <Switch>
      {/* <PrivateRoute path={[dashboardRoutes.DASHBOARD, '/']} exact component={DashboardPage} /> */}
      {/* <Route exact path={authRoutes.LOGIN} component={LoginPage} /> */}
      {/* <Route exact path={authRoutes.GOOGLE} component={AuthGoogle} /> */}
      <Route exact path={getRoutesValues(joinMindTrustRoutes)} component={JoinMindTrustRoutes} />
      {/* <Route exact path={getRoutesValues(requestProjectRoutes)} component={ProjectRequestRoutes} /> */}
      {/* <Route exact path={getRoutesValues(requestDemoRoutes)} component={DemoRequestRoutes} /> */}
      <Route exact path={getRoutesValues(talentPartnersRoutes)} component={TalentPartnersRoutes} />
      {/* <Route exact path="/healthz" component={() => <div>OK</div>} /> */}
      {/* <Route path="*" render={() => <h1>Hello 404</h1>} /> */}
      <Route
        path="*"
        component={() => {
          window.location.href = 'https://mindtrust.com/404';
          return null;
        }}
      />
    </Switch>
  );
};
