import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { Button, ButtonGroup, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { EducationType } from 'types/gql-generated';
import { validate } from 'utils/form-utils';
import * as Yup from 'yup';
import styles from './JoinMindTrust6.module.scss';
import { EducationForm, EducationPeriod, educationPeriodDefaults } from 'types/join-mindtrust.types';

const minYear: number = 1940;
const maxYear: number = moment().year();

const validationSchema = () =>
  Yup.object<EducationPeriod>()
    .shape({
      type: Yup.mixed()
        .oneOf([EducationType.HIGH_SCHOOL, EducationType.COLLEGE, EducationType.GRAD_OR_OTHER])
        .required(),
      name: Yup.string().required('Field is required'),
      didGraduate: Yup.boolean().required(),
      degree: Yup.string().when('type', {
        is: (value) => value !== EducationType.HIGH_SCHOOL,
        then: Yup.string().nullable().required('Field is required'),
        otherwise: Yup.string().nullable().notRequired(),
      }),
      from: Yup.number().when('type', {
        is: (value) => value === EducationType.HIGH_SCHOOL,
        then: Yup.number()
          .min(minYear, 'Year is not correct')
          .max(maxYear, 'Can’t be later than current year')
          .typeError('Field is required'),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      to: Yup.number().when('type', {
        is: (value) => value === EducationType.HIGH_SCHOOL,
        then: Yup.number()
          .test('to', 'Can’t be earlier than from year', function (item) {
            return item > this.parent.from;
          })
          .min(minYear, 'Year is not correct')
          .max(maxYear, 'Can’t be later than current year')
          .typeError('Field is required'),
        otherwise: Yup.number().nullable().notRequired(),
      }),
      dateAchieved: Yup.number()
        .nullable()
        .when('type', {
          is: (value) => value === EducationType.HIGH_SCHOOL,
          then: Yup.number().nullable().notRequired(),
          otherwise: Yup.number()
            .nullable()
            .min(minYear, 'Year is not correct')
            .max(maxYear, 'Can’t be later than current year')
            .typeError('Field is required'),
        }),
    })
    .required();

const getNamePlaceHolder = (educationType: EducationType): string => {
  switch (educationType) {
    case EducationType.HIGH_SCHOOL:
      return 'High school name';
    case EducationType.COLLEGE:
      return 'College name';
    default:
      return 'Graduate or other school name';
  }
};

const collegeDefaults = () => ({ ...educationPeriodDefaults(), type: EducationType.COLLEGE });
const otherDefaults = () => ({ ...educationPeriodDefaults(), type: EducationType.GRAD_OR_OTHER });
interface Props {
  step6?: EducationForm | null;
  onNext: (education: EducationForm) => void;
  goBack: () => void;
}

export const JoinMindTrust6: React.FC<Props> = ({ step6, onNext, goBack }) => {
  const [periods, setPeriods] = React.useState<EducationPeriod[]>(step6?.periods || []);
  const [selectedPeriod, setSelectedPeriod] = React.useState<EducationPeriod>(
    (step6?.periods && step6.periods[0]) || educationPeriodDefaults(),
  );

  const formik = useFormik<EducationPeriod>({
    enableReinitialize: true,
    initialValues: selectedPeriod,
    onSubmit: (period: EducationPeriod) => {
      setPeriods(periods.filter(({ name, type }) => name !== period.name && type !== period.type));
      setPeriods([...periods, period]);
      if (period.type === EducationType.HIGH_SCHOOL) setSelectedPeriod(collegeDefaults());
      if (period.type === EducationType.COLLEGE) setSelectedPeriod(otherDefaults());
      if (period.type !== EducationType.GRAD_OR_OTHER) formik.resetForm();
    },
    validate: validate<EducationPeriod>(validationSchema),
  });
  const { values, errors, touched } = formik;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="flow-content">
        <h1>Education</h1>
        <p>Let us know about your education background.</p>
        <div className="d-flex align-items-center mt-3 mb-3">
          <ButtonGroup className={classNames(styles.button_group, 'c-gray')} size="sm">
            <Button
              className="c-gray"
              active={values.type === EducationType.HIGH_SCHOOL}
              outline
              onClick={() => {
                setSelectedPeriod(periods[0] || educationPeriodDefaults());
              }}
            >
              Hight School
            </Button>
            <Button
              className="c-gray"
              active={values.type === EducationType.COLLEGE}
              disabled={periods.length < 1}
              outline
              onClick={() => {
                setSelectedPeriod(periods[1] || collegeDefaults());
              }}
            >
              College
            </Button>
            <Button
              className="c-gray"
              active={values.type === EducationType.GRAD_OR_OTHER}
              disabled={periods.length < 2}
              outline
              onClick={() => {
                setSelectedPeriod(periods[2] || otherDefaults());
              }}
            >
              Grand/Other #1
            </Button>
          </ButtonGroup>
          {periods.length < 3 && (
            <Button p={0} size="sm" type="submit" color="primary" className={styles.add_more} outline>
              <i className="icon-add" />
              Add
            </Button>
          )}
        </div>
        <FormGroup>
          <label htmlFor="name">Name</label>
          <Input
            type="text"
            name="name"
            placeholder={getNamePlaceHolder(values.type)}
            valid={touched.name && !errors.name}
            invalid={touched.name && !!errors.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={values.name}
            required
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>
        {values.type === EducationType.HIGH_SCHOOL ? (
          <Row>
            <Col sm={6}>
              <FormGroup>
                <div
                  className={classNames('d-flex align-items-start form-input', {
                    'is-invalid': touched.from && !!errors[`from`],
                    'is-valid': touched.from && !errors[`from`],
                  })}
                >
                  <label className={styles.label} htmlFor="from">
                    From
                  </label>
                  <div>
                    <Input
                      type="number"
                      name="from"
                      placeholder="Start Year"
                      valid={touched.from && !errors.from}
                      invalid={touched.from && !!errors.from}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={values.from || ''}
                      required
                    />
                    <FormFeedback>{errors.from}</FormFeedback>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <div
                  className={classNames('d-flex align-items-start form-input', {
                    'is-invalid': touched.to && !!errors[`to`],
                    'is-valid': touched.to && !errors[`to`],
                  })}
                >
                  <label className={styles.label} htmlFor="to">
                    To
                  </label>
                  <div>
                    <Input
                      type="number"
                      name="to"
                      placeholder="Finish Year"
                      valid={touched.to && !errors.to}
                      invalid={touched.to && !!errors.to}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={values.to || ''}
                      required
                    />
                    <FormFeedback>{errors.from}</FormFeedback>
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="degree">Degree</label>
                <Input
                  type="text"
                  name="degree"
                  placeholder="What degree did you achieve?"
                  valid={touched.degree && !errors.degree}
                  invalid={touched.degree && !!errors.degree}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.degree || ''}
                  required
                />
                <FormFeedback>{errors.degree}</FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <label htmlFor="">Year achieved</label>
                <Input
                  type="number"
                  name="dateAchieved"
                  placeholder="Graduation year"
                  valid={touched.dateAchieved && !errors.dateAchieved}
                  invalid={touched.dateAchieved && !!errors.dateAchieved}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={values.dateAchieved || ''}
                  required
                />
                <FormFeedback>{errors.dateAchieved}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        )}
        <FormGroup className="d-flex w-100 justify-content-between align-items-center">
          <label>Did you graduate?</label>
          <FormGroup className="d-flex justify-content-end align-items-center mb-0">
            <CustomInput
              type="radio"
              id="graduate-yes"
              label="Yes"
              onChange={() => {
                formik.setFieldValue('didGraduate', true, true);
              }}
              required
              checked={values.didGraduate}
            />
            <CustomInput
              className="ml-3"
              type="radio"
              id="graduate-no"
              label="No"
              onChange={() => {
                formik.setFieldValue('didGraduate', false, true);
              }}
              required
              checked={!values.didGraduate}
            />
          </FormGroup>
        </FormGroup>
      </div>
      <Row className="gap-xs">
        <Col xs={6}>
          <Button className="btn-block c-gray" color="default" onClick={goBack}>
            <i className="btn-icon-back" />
            Back
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            className="btn-block"
            color="primary"
            disabled={!periods.length}
            onClick={() => {
              onNext({ periods });
            }}
          >
            Next
            <i className="btn-icon-next" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
