import React from 'react';
import cn from 'classnames';
import { SelectedItem } from 'components/SelectedItem';
import { CustomInput, Input } from 'reactstrap';
import debounce from 'debounce-promise';
import styles from './MultipleSelect.module.scss';
import { Scrollbars } from 'react-custom-scrollbars';

interface Props {
  className?: string;
  title: string;
  placeholder?: string;
  options: string[];
  selected: string[];
  loadOptions?: (searchInput?: string) => void;
  onSelect: (values: string[]) => void;
}

export const MultipleSelect: React.FC<Props> = ({
  className,
  placeholder,
  title,
  selected,
  options,
  onSelect,
  loadOptions,
}) => {
  const [searchInput, setSearchInput] = React.useState('');
  const loadMoreOptions = React.useMemo(() => loadOptions && debounce(loadOptions, 500, { leading: true }), [
    loadOptions,
  ]);

  React.useEffect(() => {
    loadMoreOptions && loadMoreOptions(searchInput);
  }, [loadMoreOptions, searchInput]);

  const onInputChange = (event: any) => {
    const { value } = event.target;
    setSearchInput(value);
  };

  const onCheck = React.useCallback(
    (value: string) => {
      selected.find((s) => s === value)
        ? onSelect(selected.filter((s) => s !== value))
        : onSelect([...selected, value]);
    },
    [onSelect, selected],
  );

  const onRemove = React.useCallback(
    (value: string) => {
      onSelect(selected.filter((s) => s !== value));
    },
    [onSelect, selected],
  );

  const filtered = React.useMemo(() => {
    return options.filter((o) => o.toLowerCase().includes(searchInput.toLowerCase()));
  }, [options, searchInput]);

  return (
    <div className={cn(styles.MultipleSelect, className)}>
      {!!selected.length && (
        <div className={styles.selected}>
          <p className={`label ${styles.label}`}>Selected</p>
          <div className={styles.selected_items}>
            {selected.map((v, i) => (
              <SelectedItem key={`s_${v}_${i}`} value={v} onClose={onRemove} />
            ))}
          </div>
        </div>
      )}
      <Input type="text" placeholder={placeholder} onChange={onInputChange} value={searchInput} />
      <p className={`label mt-3 ${styles.label}`}>{title}</p>
      <Scrollbars className={styles.scroll_wrapper}>
        <ul className={`${styles.option_list} ${styles.checkbox_list}`}>
          {filtered.map((v, i) => (
            <CustomInput
              className={styles.custom_checkbox}
              type="checkbox"
              key={`${v}_${i}`}
              id={v}
              label={v}
              onChange={() => onCheck(v)}
              checked={!!selected.find((s) => s === v)}
            />
          ))}
        </ul>
      </Scrollbars>
    </div>
  );
};
