/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum TalentType {
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  INDIVIDUAL = 'INDIVIDUAL',
}

enum AuthenticationScheme {
  GOOGLE = 'GOOGLE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
}

enum EducationType {
  COLLEGE = 'COLLEGE',
  GRAD_OR_OTHER = 'GRAD_OR_OTHER',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
}

enum ParticipationType {
  BUSINESS = 'BUSINESS',
  PROJECT_TEAMS = 'PROJECT_TEAMS',
  SPECIFIC_JOB = 'SPECIFIC_JOB',
}

enum ProjectRequestType {
  EXISTING_PROJECT = 'EXISTING_PROJECT',
  LEARN_ABOUT = 'LEARN_ABOUT',
  NEW_PROJECT = 'NEW_PROJECT',
  ONGOING_ASSISTANCE = 'ONGOING_ASSISTANCE',
}

enum Role {
  MT_ADMIN = 'MT_ADMIN',
  MT_CONTENT_ADMIN = 'MT_CONTENT_ADMIN',
  MT_CONTENT_EDITOR = 'MT_CONTENT_EDITOR',
  MT_SUPER_ADMIN = 'MT_SUPER_ADMIN',
  SYSTEM_REFRESH_TOKEN = 'SYSTEM_REFRESH_TOKEN',
}

enum Weekday {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED',
}

export interface TalentAdditionalInformationInput {
  timezone: string;
  workingTimezonePreference?: string | null;
  companyCertificates?: string | null;
  isAvailableTime?: boolean | null;
  learnMindtrust?: string | null;
  whoReferred?: string | null;
  referralCode?: string | null;
  details?: string | null;
}

export interface TalentAvailabilityInput {
  isPartTimeHourly?: boolean | null;
  timezone: string;
  workingTimezonePreference?: string;
  startFrom: string;
  commitment: string;
  rateUsd: number;
  isAvailableHourly?: boolean | null;
  wantTrialProject?: boolean | null;
  workDays: Weekday[];
}

export interface TalentBasicInformationInput {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companySize?: string;
  companyLegalName?: string;
  privacyPolicyAccepted: boolean;
}

export interface TalentContactInformationInput {
  city: string;
  country: string;
  line1: string;
  line2?: string | null;
  postalCode: string;
  state?: string | null;
  skypeId?: string | null;
}

export interface TalentEducationInput {
  periods?: EducationPeriodInput[] | null;
}

export interface TalentInput {
  id?: string | null;
  basicInformation: TalentBasicInformationInput;
  contactInformation?: TalentContactInformationInput | null;
  personalInformation?: TalentPersonalInformationInput | null;
  workExperience?: TalentWorkExperienceInput | null;
  education?: TalentEducationInput | null;
  participation?: TalentParticipationInput | null;
  availability?: TalentAvailabilityInput | null;
  skillExperience?: TalentSkillExperienceInput | null;
  portfolio?: TalentPortfolioInput | null;
  languages?: TalentLanguagesInput | null;
  workReferences?: TalentWorkReferencesInput | null;
  additionalInformation?: TalentAdditionalInformationInput | null;
  submit?: TalentSubmitInput | null;
  clientSpecific?: any | null;
}

export interface TalentLanguagesInput {
  rateEnglish: string;
  nativeLanguage?: string | null;
  additionalLanguages?: LanguageInput[] | null;
}

export interface TalentParticipationInput {
  type: ParticipationType;
  expertise?: string[] | null;
  role?: string | null;
  jobOfferId?: string | null;
}

export interface TalentPersonalInformationInput {
  gender: string;
  pronoun: string;
  birthday: string;
  ssn?: string;
}

export interface TalentPortfolioInput {
  portfolioUrls?: string[] | null;
  relevantWorkUrls?: string[] | null;
  awards?: string | null;
}

export interface TalentSkillExperienceInput {
  skills: TalentSkillInput[];
}

export interface TalentSkillInput {
  skill: string;
  experience: number;
  years: number;
}

export interface TalentSubmitInput {
  legalName: string;
  personallyCertified: boolean;
  date: string;
}

export interface TalentWorkExperienceInput {
  linkedInUrl?: string | null;
  cvUrl: string;
  periods?: WorkExperiencePeriodInput[] | null;
}

export interface TalentWorkReferencesInput {
  references?: WorkReferenceInput[] | null;
}

interface AuthenticationInput {
  scheme: AuthenticationScheme;
  refreshToken?: string | null;
  googleCode?: string | null;
  googleRedirectUri?: string | null;
}

interface BookMeetingInput {
  projectRequestId: string;
  startTime: any;
  duration: number;
}

interface CmsSearchInput {
  value?: string | null;
  limit?: number | null;
}

interface EducationPeriodInput {
  type: EducationType;
  name: string;
  degree?: string | null;
  didGraduate: boolean;
  from?: string | null;
  to?: string | null;
  dateAchieved?: string | null;
}

interface EmailInput {
  email: string;
}

interface LanguageInput {
  language: string;
  verbalProficiency: number;
  writtenProficiency: number;
}

interface ProjectRequestCmsMetaInput {
  silo?: string | null;
  skill?: string | null;
}

interface ProjectRequestInput {
  firstName: string;
  lastName: string;
  email: string;
  id?: string | null;
  phoneNumber: string;
  companyName: string;
  websiteUrl?: string | null;
  type?: ProjectRequestType | null;
  services?: string[] | null;
  skills?: string[] | null;
  budgetFromUsd?: number | null;
  budgetToUsd?: number | null;
  whenToStart?: string | null;
  additionalDetails?: string | null;
  cmsMeta?: ProjectRequestCmsMetaInput | null;
  isComplete?: boolean | null;
  optOutOfEmails?: boolean | null;
  privacyPolicyAccepted?: boolean | null;
}

interface WorkExperiencePeriodInput {
  employerName: string;
  designation: string;
  responsibilities: string;
  from: string;
  to: string;
}

interface WorkReferenceInput {
  firstName: string;
  lastName: string;
  email: string;
  relationship: string;
  companyName: string;
  phoneNumber: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
