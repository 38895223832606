import { GQLConnector } from 'store/qgl-connector';
import { CHECK_HUB_SPOT, SYNC_HUB_SPOT, SYNC_SILO, BUILD_SITE_HENDRIX, BUILD_SITE_MASTER, BUILD_SITE_DYNAMIC } from 'queries/silo-queries';
import { SyncSiloQL } from 'types/gql-generated/SyncSiloQL';
import { HubspotCheckPropertiesQL } from '../../types/gql-generated/HubspotCheckPropertiesQL';
import { HubspotSyncPropertiesQL } from 'types/gql-generated/HubspotSyncPropertiesQL';

export class TestService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  syncSilo = async (): Promise<SyncSiloQL['cmsSync']> =>
    (
      await this.qglConnector.mutate<SyncSiloQL>({
        mutation: SYNC_SILO,
      })
    ).cmsSync;

  checkHubSpot = async (): Promise<HubspotCheckPropertiesQL['hubspotCheckProperties']> =>
    (
      await this.qglConnector.mutate<HubspotCheckPropertiesQL>({
        mutation: CHECK_HUB_SPOT,
      })
    ).hubspotCheckProperties;

  syncHubSpot = async (): Promise<HubspotSyncPropertiesQL['hubspotSyncProperties']> =>
    (
      await this.qglConnector.mutate<HubspotSyncPropertiesQL>({
        mutation: SYNC_HUB_SPOT,
      })
    ).hubspotSyncProperties;

  buildSiteHendrix = async (): Promise<any> =>
    (
      await this.qglConnector.mutate<any>({
        mutation: BUILD_SITE_HENDRIX,
      })
    ).buildSiteHendrix;

  buildSiteMaster = async (): Promise<any> =>
    (
      await this.qglConnector.mutate<any>({
        mutation: BUILD_SITE_MASTER,
      })
    ).buildSiteMaster;
  
  buildSiteDynamic = async (): Promise<any> =>
    (
      await this.qglConnector.mutate<any>({
        mutation: BUILD_SITE_DYNAMIC,
      })
    ).buildSiteDynamic;
}
