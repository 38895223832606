import classNames from 'classnames';
import React from 'react';
import {
  DesignIcon,
  DevelopmentIcon,
  MarketingIcon,
  ProductionIcon,
  StrategyIcon,
  TechnologyIcon,
} from 'svg/icons-colorful';
import styles from './ExpertiseBadge.module.scss';

interface Props {
  className?: string;
  name: 'design' | 'development' | 'marketing' | 'production' | 'strategy' | 'technology';
  selected?: boolean;
  onClick?: () => void;
}

export const ExpertiseBadge: React.FC<Props> = ({ className, name, selected, onClick }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.selected]: selected }, className)} onClick={onClick}>
      {name === 'design' && <DesignIcon className={styles.icon} />}
      {name === 'development' && <DevelopmentIcon className={styles.icon} />}
      {name === 'marketing' && <MarketingIcon className={styles.icon} />}
      {name === 'production' && <ProductionIcon className={styles.icon} />}
      {name === 'strategy' && <StrategyIcon className={styles.icon} />}
      {name === 'technology' && <TechnologyIcon className={styles.icon} />}
      {name}
    </div>
  );
};
