import React from 'react';
import classNames from 'classnames';
import { MtLogoDarkIcon } from 'svg/icons-colorful';
import styles from './TalentPartnersLayout.module.scss';

interface Props {
  className?: string;
}

export const TalentPartnersLayout: React.FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames(styles.talentPartnersLayout, className)}>
      <MtLogoDarkIcon className={styles.logo} />
      {children}
      <p className="my-3 text-center">
        Need some help?{' '}
        <a href="mailto:hr@mindtrust.com" className="hyper-link-text">
          Email Human Resources.{' '}
        </a>
      </p>
    </div>
  );
};
