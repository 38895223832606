import React from 'react';
import classNames from 'classnames';
import { BusinessIcon, ProjectIcon } from 'svg/icons-colorful';
import styles from './ProjectSide.module.scss';

interface Props {
  className?: string;
  type: 'project' | 'business';
  active?: boolean;
  onClick?: () => void;
}

export const ProjectSide: React.FC<Props> = ({ className, type, active, onClick }) => {
  return (
    <div className={classNames(styles.wrapper, { [styles.active]: active }, className)} onClick={onClick}>
      {type === 'business' && <BusinessIcon className={classNames(styles.icon, { [styles.active]: active })} />}
      {type === 'project' && <ProjectIcon className={classNames(styles.icon, { [styles.active]: active })} />}
      <h3 className={styles.title}>
        {type === 'business' && 'Work on the business.'}
        {type === 'project' && 'Work on project teams.'}
      </h3>
      <p className={styles.description}>
        {type === 'business' && 'Help MindTrust build the future of work.'}
        {type === 'project' && 'Deliver world-class solutions for industry leading companies.'}
      </p>
    </div>
  );
};
