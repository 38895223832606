import { action, computed, observable } from 'mobx';
import { DomainsStore } from 'store/domains';
import { SiloSkill } from 'types/request.types';
import { UiStore } from './ui.store';
import { talentPartnersRoutes } from 'routing';
import { TalentPartnersForm, TalentPartnersFormErrors, TalentPartnersInput } from 'types/talent-partners.types';
import { ERROR_CODES } from 'types/error.types';

export class TalentPartnersPage {
  @observable private _skills: SiloSkill[] = [];
  @observable private _talentPartnersForm: TalentPartnersForm;
  @observable private _isLoading: boolean = false;
  @observable public errors?: TalentPartnersFormErrors;

  constructor(private uiStore: UiStore, private domainsStore: DomainsStore) {
    const talentPartnersForm = this.domainsStore.talentPartners.readApplicationFormToLocalStorage();
    this._talentPartnersForm = talentPartnersForm || { nextStep: 1 };
  }

  @computed
  get skillsOptions() {
    return this._skills.map(({ skill }) => skill);
  }

  get isLoading() {
    return this._isLoading;
  }

  @computed
  get nextStep() {
    return this._talentPartnersForm.nextStep;
  }

  @computed
  get talentPartnersForm(): TalentPartnersForm {
    return this._talentPartnersForm;
  }

  getReview() {
    return this.domainsStore.reviews.getReview();
  }

  private processExperience = async ({
    cvInformation,
    workingPeriods,
  }: TalentPartnersForm): Promise<TalentPartnersInput['workExperience']> => {
    if (!cvInformation) return;
    if (cvInformation.file) {
      const url = await this.domainsStore.talentPartners.uploadFile(cvInformation.file);
      this._talentPartnersForm = {
        ...this._talentPartnersForm,
        cvInformation: {
          ...cvInformation,
          file: null,
          fileInfo: {
            name: cvInformation.file.name,
            size: cvInformation.file.size,
            url,
          },
        },
      };
      return {
        linkedInUrl: cvInformation.linkedInUrl,
        cvUrl: url,
        periods: workingPeriods?.periods,
      };
    }
    return {
      linkedInUrl: cvInformation.linkedInUrl,
      cvUrl: cvInformation.fileInfo?.url!,
      periods: workingPeriods?.periods,
    };
  };

  private processEducation = ({ education }: TalentPartnersForm): TalentPartnersInput['education'] => {
    if (!education) return;
    return {
      periods:
        education?.periods?.map((period) => ({
          ...period,
          // convert years into first date
          dateAchieved: period.dateAchieved ? `${period.dateAchieved}-01-01` : null,
          from: `${period.from}-01-01`,
          to: `${period.to}-01-01`,
        })) || [],
    };
  };

  @action
  nextForm = async (step: number) => {
    this.uiStore.routerStore.push(talentPartnersRoutes.request(step));
  }

  @action
  saveApplicationForm = async (talentPartners: TalentPartnersForm) => {
    this._isLoading = true;
    // const nextStep = Math.max(this._talentPartnersForm.nextStep, talentPartners.nextStep);
    const nextStep = talentPartners.nextStep;
    this._talentPartnersForm = { ...this._talentPartnersForm, ...talentPartners, nextStep };
    if (!this._talentPartnersForm.basicInformation) {
      return this.uiStore.toastStore.showErrorMessage('Form is not filled');
    }
    try {
      // const id = await this.domainsStore.talentPartners.sendApplicationForm({
      //   id: this._talentPartnersForm.id,
      //   basicInformation: this._talentPartnersForm.basicInformation,
      //   contactInformation: this._talentPartnersForm.contactInformation,
      //   personalInformation: this._talentPartnersForm.personalInformation,
      //   workExperience: await this.processExperience(this._talentPartnersForm),
      //   education: this.processEducation(this._talentPartnersForm),
      //   participation: this._talentPartnersForm.participation,
      //   availability: this._talentPartnersForm.availability,
      //   skillExperience: this._talentPartnersForm.skills,
      //   portfolio: this._talentPartnersForm.portfolio,
      //   languages: this._talentPartnersForm.languages,
      //   workReferences: this._talentPartnersForm.workReferences,
      //   additionalInformation: this._talentPartnersForm.additionalInformation,
      //   submit: this._talentPartnersForm.submit,
      //   clientSpecific: {
      //     nextStep,
      //     cvInformation: this._talentPartnersForm.cvInformation,
      //   },
      // });
      const id = nextStep.toString();  // generate for random id for the testing
      this._talentPartnersForm = { ...this._talentPartnersForm, id };
      this.domainsStore.talentPartners.sendApplicationToToLocalStorage(this._talentPartnersForm);
      this.uiStore.routerStore.push(talentPartnersRoutes.request(talentPartners.nextStep));
      
    } catch (error) {
      if (error.errorCode === ERROR_CODES.APPLICANT_EMAIL_IS_TAKEN) {
        const { email } = this._talentPartnersForm.basicInformation!;
        this.errors = {
          ...this.errors,
          basicInformation: {
            ...this.errors?.basicInformation,
            email: `${error.message} (please verify mailbox)`,
          },
        };
        this.domainsStore.talentPartners.sendEmail(email);
      } else {
        this.uiStore.toastStore.showError(error);
      }
    }
    this._isLoading = false;
  };

  @action.bound
  async restoreFromServer(id: string) {
    try {
      this._isLoading = true;
      this._talentPartnersForm = await this.domainsStore.talentPartners.restoreFormServer(id);
      this.domainsStore.talentPartners.sendApplicationToToLocalStorage(this._talentPartnersForm);
      this.uiStore.routerStore.push(talentPartnersRoutes.request(this._talentPartnersForm.nextStep));
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this._isLoading = false;
  }
  @action.bound
  async searchSkillsOptions(input: string = '') {
    try {
      this._isLoading = true;
      this._skills = await this.domainsStore.talentPartners.searchSiloSkills({ value: input });
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this._isLoading = false;
  }

  @action.bound
  goBack(step: number) {
    this.uiStore.routerStore.push(talentPartnersRoutes.request(step));
  }

  @action.bound
  clearForm() {
    this.domainsStore.talentPartners.clearApplicationFormToLocalStorage();
    this.uiStore.toastStore.showSuccessMessage('Application has been sent!');
  }
}
