import isMobilePhone from 'validator/lib/isMobilePhone';
import * as Yup from 'yup';

type FormValidationSchema<FormValues extends object> = Yup.ObjectSchema<Yup.Shape<object, FormValues>>;

export const getErrorsFromValidationError = (validationError: { inner: string[] }) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: { [key: string]: string }, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const validate = <T extends object>(
  getValidationSchema: () => FormValidationSchema<T>,
  preProcessValues?: (_: T) => T,
) => {
  return (values: T) => {
    const validationSchema = getValidationSchema();
    try {
      const processed = preProcessValues ? preProcessValues(values) : values;
      validationSchema.validateSync(processed, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

export const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9\\-]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const transformedNumber = Yup.number().transform((value) => (isNaN(value) ? 0 : value));

export const validators = {
  email: Yup.string().email('Please enter a valid email.').required('This is field required.'),
  password: Yup.string()
    .min(6, `Password has to be at least ${6} characters!`)
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, 'Password must contain: numbers, uppercase and lowercase characters')
    .required('Password is required'),
  repeatPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    otherwise: Yup.string().min(6, `Enter new password first.`),
  }),
  countryCode: transformedNumber.moreThan(0, 'Code is required'),
  phoneNumber: Yup.string()
    .test('phoneNumber', 'Please provide correct phone number.', function (item) {
      return isMobilePhone(`${item}`.replace(/(\(|\)|\s|-)/g, ''), undefined, { strictMode: true });
    })
    .required(),
  firstName: Yup.string()
    .min(3, 'First name should be at least 3 characters.')
    .max(25, 'First name can be 25 characters or less.')
    .required('This field is required.'),
  lastName: Yup.string()
    .min(3, 'Last name should be at least 3 characters.')
    .max(25, 'Last name can be 25 characters or less.')
    .required('This field is required.'),
  accept: Yup.array<string>()
    .required('* required')
    .test('accept', 'You have to accept our Terms of Use!', (value) => !!value?.length),
  code: Yup.string().min(6, 'Code is incorrect').required('Code is required'),
  title: Yup.string()
    .min(3, 'Quicklink Title should be at least 3 characters.')
    .max(42, 'Quicklink Title can be 42 characters or less.')
    .required('Quicklink Title is required!'),
  url: Yup.string().url().required('Quicklink URL is required!'),
  creditCategory: Yup.string().required('Category is required!'),
  companyName: Yup.string().required('This field is required.'),
  websiteUrl: Yup.string()
    .transform((value, originalValue) => (!value ? null : originalValue))
    .matches(
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      'Please enter a valid domain.',
    )
    .nullable(),
};
