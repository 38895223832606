import { ApplicantType } from './gql-generated';
import * as Moment from 'moment';
import * as MomentRange from 'moment-range';
import { TimezoneOption, TIME_ZONE_OPTIONS } from 'utils/timezone-utils';
import { LANGUAGES } from 'utils/languages-utils';
const countryTelephoneData = require('country-telephone-data');
const states = require('usa-states');

const moment = MomentRange.extendMoment(Moment);

export const getSelectOption = <T>(value: T, options: SelectOption<T>[]): SelectOption<T> | undefined =>
  options.find((o) => o.value === value);

export interface SelectOption<T> {
  label: string;
  value: T;
}

export const APPLICANT_TYPE_ROLES_SELECT_OPTIONS: SelectOption<ApplicantType>[] = [
  {
    label: 'Individual',
    value: ApplicantType.INDIVIDUAL,
  },
  {
    label: 'Business',
    value: ApplicantType.BUSINESS_ENTITY,
  },
];

export const COMPANY_SIZE_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select size',
    value: null,
  },
  {
    label: '1-10',
    value: '1-10',
  },
  {
    label: '10-100',
    value: '10-100',
  },
  {
    label: '100+',
    value: '100+',
  },
];

export const COUNTRIES_SELECT_OPTIONS: SelectOption<string>[] = [
  { label: 'Select country', value: '' },
  ...countryTelephoneData.allCountries.map(({ name, iso2 }: { name: string; iso2: string }) => ({
    label: name,
    value: iso2,
  })),
];

export const USA_STATES_SELECT_OPTIONS: SelectOption<string>[] = new states.UsaStates().states.map(
  ({ name }: { name: string }) => ({
    label: name,
    value: name,
  }),
);

export const GENDER_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select your gender',
    value: null,
  },
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Non-binary',
    value: 'Non-binary',
  },
  {
    label: 'Transgender',
    value: 'Transgender',
  },
  {
    label: 'Intersex',
    value: 'Intersex',
  },
  {
    label: 'I prefer not to say',
    value: 'I prefer not to say',
  },
];

export const PRONOUN_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select your pronoun',
    value: null,
  },
  {
    label: 'He/Him',
    value: 'He/Him',
  },
  {
    label: 'She/Her',
    value: 'She/Her',
  },
  {
    label: 'They/Them',
    value: 'They/Them',
  },
];

export const MONTH_SELECT_OPTIONS: SelectOption<string | number>[] = moment
  .months()
  .map((label, value) => ({ label, value }));

const startYear = moment().year(1940);
export const YEAR_SELECT_OPTIONS: SelectOption<string | number>[] = Array.from(
  moment.range(startYear, moment()).by('year'),
).map((label) => ({
  label: `${label.year()}`,
  value: label.year(),
}));

export const BUSINESS_ROLES_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select role',
    value: null,
  },
  ...['Finance and Accounting', 'Sales', 'Human Resources', 'Operations'].map((value) => ({ label: value, value })),
];

export const COMMITMENT_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select commitment',
    value: null,
  },
  ...['1 - 10 hours', '10 - 20 hours', '20 - 30 hours', '30 - 40 hours', '40+ hours'].map((value) => ({ label: value, value })),
];

export const TIME_ZONE_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select time zone',
    value: null,
  },
  ...TIME_ZONE_OPTIONS.map(({ label, value }) => ({ label, value })),
];

export const TIME_ZONE_GROUPED_SELECT_OPTIONS = TIME_ZONE_OPTIONS.reduce<
  {
    label: string;
    options: TimezoneOption[];
  }[]
>((acc, option) => {
  const groupLabel = option.value.split('/')[0];
  const group = acc.find(({ label }) => label === groupLabel);
  if (group) {
    group.options = [...group.options, option];
  } else {
    acc.push({
      label: groupLabel,
      options: [option],
    });
  }
  return acc;
}, []);

export const LANGUAGES_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Native language',
    value: null,
  },
  ...LANGUAGES.map((label) => ({ label, value: label })),
];

export const RATE_ENGLISH_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Rate your english',
    value: null,
  },
  ...[
    'Basic: Limited vocabulary and understanding of simple phrases.',
    'Intermediate: Comfortable with everyday conversation and written communication.',
    'Advanced: Strong grasp of complex language, able to articulate nuanced ideas.',
    'Fluent: Native-level proficiency in both verbal and written forms.',
  ].map((label) => ({ label, value: label })),
];

export const KNOW_MINDTRUST_SELECT_OPTIONS: SelectOption<string | null>[] = [
  {
    label: 'Select an option',
    value: null,
  },
  ...[
    'Referral',
    'Technology Fair/ Presentation',
    'MindTrust Website/ Internet',
    'MindTrust Brochure/ Ad',
    'Social Media',
    'TV/ Radio/ Print Media',
  ].map((label) => ({ label, value: label })),
];

export type ModeOptions = 'Dashboard' | 'Budget' | 'Allocation' | 'Project Mgmt';

export type NavigationOptions =
  | 'Overview'
  | 'Quicklinks'
  | 'Info'
  | 'Details'
  | 'Invoices'
  | 'Roadmap'
  | 'Backlog'
  | 'Board'
  | 'Reports';

export type ActionOptions = 'Sync QB' | 'Edit' | 'Duplicate' | 'Archive' | 'Delete';

export const MODE_SELECT_OPTIONS: SelectOption<ModeOptions>[] = [
  {
    label: 'Dashboard',
    value: 'Dashboard',
  },
  {
    label: 'Budget',
    value: 'Budget',
  },
  {
    label: 'Allocation',
    value: 'Allocation',
  },
  {
    label: 'Project Mgmt',
    value: 'Project Mgmt',
  },
];

export const ACTION_SELECT_OPTIONS: SelectOption<ActionOptions>[] = [
  {
    label: 'Sync QB',
    value: 'Sync QB',
  },
  {
    label: 'Edit',
    value: 'Edit',
  },
  {
    label: 'Duplicate',
    value: 'Duplicate',
  },
  {
    label: 'Archive',
    value: 'Archive',
  },
  {
    label: 'Delete',
    value: 'Delete',
  },
];
