import { action, computed, observable } from 'mobx';
import { DomainsStore } from 'store/domains';
import { SiloSkill } from 'types/request.types';
import { UiStore } from './ui.store';
import { joinMindTrustRoutes } from 'routing';
import { JoinMindtrustForm, JoinMindtrustFormErrors, JoinMindTrustInput } from 'types/join-mindtrust.types';
import { ERROR_CODES } from 'types/error.types';

export class JoinMindtrustPage {
  @observable private _skills: SiloSkill[] = [];
  @observable private _joinMindtrustForm: JoinMindtrustForm;
  @observable private _isLoading: boolean = false;
  @observable public errors?: JoinMindtrustFormErrors;

  constructor(private uiStore: UiStore, private domainsStore: DomainsStore) {
    const joinMindtrustForm = this.domainsStore.joinMindtrust.readApplicationFormToLocalStorage();
    this._joinMindtrustForm = joinMindtrustForm || { nextStep: 1 };
  }

  @computed
  get skillsOptions() {
    return this._skills.map(({ skill }) => skill);
  }

  get isLoading() {
    return this._isLoading;
  }

  @computed
  get nextStep() {
    return this._joinMindtrustForm.nextStep;
  }

  @computed
  get joinMindtrustForm(): JoinMindtrustForm {
    return this._joinMindtrustForm;
  }

  getReview() {
    return this.domainsStore.reviews.getReview();
  }

  private processExperience = async ({
    cvInformation,
    workingPeriods,
  }: JoinMindtrustForm): Promise<JoinMindTrustInput['workExperience']> => {
    if (!cvInformation) return;
    if (cvInformation.file) {
      const url = await this.domainsStore.joinMindtrust.uploadFile(cvInformation.file);
      this._joinMindtrustForm = {
        ...this._joinMindtrustForm,
        cvInformation: {
          ...cvInformation,
          file: null,
          fileInfo: {
            name: cvInformation.file.name,
            size: cvInformation.file.size,
            url,
          },
        },
      };
      return {
        linkedInUrl: cvInformation.linkedInUrl,
        cvUrl: url,
        periods: workingPeriods?.periods,
      };
    }
    return {
      linkedInUrl: cvInformation.linkedInUrl,
      cvUrl: cvInformation.fileInfo?.url!,
      periods: workingPeriods?.periods,
    };
  };

  private processEducation = ({ education }: JoinMindtrustForm): JoinMindTrustInput['education'] => {
    if (!education) return;
    return {
      periods:
        education?.periods?.map((period) => ({
          ...period,
          // convert years into first date
          dateAchieved: period.dateAchieved ? `${period.dateAchieved}-01-01` : null,
          from: `${period.from}-01-01`,
          to: `${period.to}-01-01`,
        })) || [],
    };
  };

  @action
  saveApplicationForm = async (joinMindtrust: JoinMindtrustForm) => {
    this._isLoading = true;
    const nextStep = Math.max(this._joinMindtrustForm.nextStep, joinMindtrust.nextStep);
    this._joinMindtrustForm = { ...this._joinMindtrustForm, ...joinMindtrust, nextStep };
    if (!this._joinMindtrustForm.basicInformation) {
      return this.uiStore.toastStore.showErrorMessage('Form is not filled');
    }
    try {
      const id = await this.domainsStore.joinMindtrust.sendApplicationForm({
        id: this._joinMindtrustForm.id,
        basicInformation: this._joinMindtrustForm.basicInformation,
        contactInformation: this._joinMindtrustForm.contactInformation,
        personalInformation: this._joinMindtrustForm.personalInformation,
        workExperience: await this.processExperience(this._joinMindtrustForm),
        education: this.processEducation(this._joinMindtrustForm),
        participation: this._joinMindtrustForm.participation,
        availability: this._joinMindtrustForm.availability,
        skillExperience: this._joinMindtrustForm.skills,
        portfolio: this._joinMindtrustForm.portfolio,
        languages: this._joinMindtrustForm.languages,
        workReferences: this._joinMindtrustForm.workReferences,
        additionalInformation: this._joinMindtrustForm.additionalInformation,
        submit: this._joinMindtrustForm.submit,
        clientSpecific: {
          nextStep,
          cvInformation: this._joinMindtrustForm.cvInformation,
        },
      });
      this._joinMindtrustForm = { ...this._joinMindtrustForm, id };
      this.domainsStore.joinMindtrust.sendApplicationToToLocalStorage(this._joinMindtrustForm);
      this.uiStore.routerStore.push(joinMindTrustRoutes.request(joinMindtrust.nextStep));
    } catch (error) {
      if (error.errorCode === ERROR_CODES.APPLICANT_EMAIL_IS_TAKEN) {
        const { email } = this._joinMindtrustForm.basicInformation!;
        this.errors = {
          ...this.errors,
          basicInformation: {
            ...this.errors?.basicInformation,
            email: `${error.message} (please verify mailbox)`,
          },
        };
        this.domainsStore.joinMindtrust.sendEmail(email);
      } else {
        this.uiStore.toastStore.showError(error);
      }
    }
    this._isLoading = false;
  };

  @action.bound
  async restoreFromServer(id: string) {
    try {
      this._isLoading = true;
      this._joinMindtrustForm = await this.domainsStore.joinMindtrust.restoreFormServer(id);
      this.domainsStore.joinMindtrust.sendApplicationToToLocalStorage(this._joinMindtrustForm);
      this.uiStore.routerStore.push(joinMindTrustRoutes.request(this._joinMindtrustForm.nextStep));
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this._isLoading = false;
  }
  @action.bound
  async searchSkillsOptions(input: string = '') {
    try {
      this._isLoading = true;
      this._skills = await this.domainsStore.joinMindtrust.searchSiloSkills({ value: input });
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this._isLoading = false;
  }

  @action.bound
  goBack(step: number) {
    this.uiStore.routerStore.push(joinMindTrustRoutes.request(step));
  }

  @action.bound
  clearForm() {
    this.domainsStore.joinMindtrust.clearApplicationFormToLocalStorage();
    this.uiStore.toastStore.showSuccessMessage('Application has been sent!');
  }
}
