import { action, computed, observable, reaction } from 'mobx';
import { DomainsStore } from 'store/domains';
import { RequestProjectDomain } from 'store/domains/request-project.domain';
import {
  ProjectRequestParams,
  SiloSkill,
  Step1ProjectRequest,
  Step2ServicesProjectRequest,
  Step2SkillsProjectRequest,
  Step3ProjectRequest,
  Step4ProjectRequest,
  Step5ProjectRequest,
} from 'types/request.types';
import { UiStore } from './ui.store';
import { SiloService } from '../../types/request.types';
import { requestProjectRoutes } from 'routing';
import queryString from 'query-string';
import { pascalCase } from 'change-case';

export class ProjectRequestPage {
  private requestProjectDomain: RequestProjectDomain;
  @observable private _services: SiloService[] = [];
  @observable private _skills: SiloSkill[] = [];
  @observable private _currentStep: number = 1;
  @observable public isLoading: boolean = false;

  constructor(private uiStore: UiStore, private domainsStore: DomainsStore) {
    this.requestProjectDomain = domainsStore.requestProject;
    reaction(
      () => this.currentStep,
      () => {
        const next = this.currentStep;
        const { search } = this.uiStore.routerStore.location;
        if (next === 2 && this.urlSiloSkill)
          this.uiStore.routerStore.push(`${requestProjectRoutes.request(next)}/resources${search}`);
        else this.uiStore.routerStore.push(`${requestProjectRoutes.request(next)}${search}`);
      },
    );
  }

  @computed
  get currentStep() {
    return this._currentStep;
  }

  set currentStep(step: number) {
    this._currentStep = Math.max(Math.min(step, 6), 1);
  }

  @computed
  get services(): string[] {
    return this._services.map(({ service }) => service);
  }

  @computed
  get skills(): string[] {
    return this._skills.map(({ skill }) => skill);
  }

  @computed
  get urlSiloSkill() {
    const { search } = this.uiStore.routerStore.location;
    const { skill } = queryString.parse(search);
    if (Array.isArray(skill)) {
      return skill[0] && pascalCase(skill[0], { delimiter: ' ' });
    }
    return skill && pascalCase(skill, { delimiter: ' ' });
  }

  @computed
  get urlSiloCategory() {
    const { search } = this.uiStore.routerStore.location;
    const { silo } = queryString.parse(search);
    if (Array.isArray(silo)) {
      return silo[0] && pascalCase(silo[0], { delimiter: ' ' });
    }
    return silo && pascalCase(silo, { delimiter: ' ' });
  }

  @computed
  get silo() {
    return this.urlSiloCategory && this.urlSiloSkill ? `${this.urlSiloSkill} ${this.urlSiloCategory}` : undefined;
  }

  @computed
  get projectRequest(): ProjectRequestParams {
    const projectRequest = this.domainsStore.requestProject.projectRequest;
    const { skills } = projectRequest;
    return {
      ...projectRequest,
      skills: skills || (this.urlSiloSkill ? [this.urlSiloSkill] : null),
    };
  }

  getReview() {
    return this.domainsStore.reviews.getReview();
  }

  @action.bound
  async searchServices() {
    try {
      this.isLoading = true;
      this._services = await this.domainsStore.requestProject.searchSiloServices({ value: '' });
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async searchSkills(input: string = '') {
    try {
      this.isLoading = true;
      this._skills = await this.domainsStore.requestProject.searchSiloSkills({ value: input });
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process1Step(step1: Step1ProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, ...step1 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 2;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process2ServicesStep(step2: Step2ServicesProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, ...step2 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 3;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process2SkillsStep(step2: Step2SkillsProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, ...step2 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 3;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process3Step(step3: Step3ProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, ...step3 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 4;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process4Step(step4: Step4ProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, ...step4 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 5;
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async process5Step(step5: Step5ProjectRequest) {
    this.requestProjectDomain.projectRequest = { ...this.projectRequest, isComplete: true, ...step5 };
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.currentStep = 6;
      this.uiStore.toastStore.showSuccessMessage('Request has been sent.');
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  async resend() {
    try {
      this.isLoading = true;
      await this.requestProjectDomain.saveProjectRequest();
      this.uiStore.toastStore.showSuccessMessage('Request has been resent.');
    } catch (error) {
      this.uiStore.toastStore.showError(error);
    }
    this.isLoading = false;
  }

  @action.bound
  goBack() {
    this.uiStore.routerStore.goBack();
  }
}
